import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router";
import { CandySidebar, DRAWER_WIDTH } from "../components/CandySidebar";
import { CandyAppBar } from "../components/CandyAppBar";

export const AppRoot = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const theme = useTheme();
  const showSidebar = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        ml: showSidebar ? `${DRAWER_WIDTH}px` : 0,
      }}
    >
      <CandySidebar
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        isNarrow={!showSidebar}
      />
      <Outlet />
      <CandyAppBar
        isMobile={!showSidebar}
        onOpenSidebar={() => setIsSidebarOpen(true)}
      />
    </Box>
  );
};
