import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSuspenseQueries } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { Order, OrderedItem, orderAPI } from "../api/OrderAPI";
import { CandyPage } from "../components/CandyPage";
import { MuiLink } from "../components/MuiLink";
import { FormattedDate } from "../components/date/FormattedDate";
import { useOrderId } from "../hooks/useOrderId";
import { CANDY_ARTICLE_PAGE_ROUTE } from "./CandyArticlePage/CandyArticlePage";

export const CANDY_ORDER_PAGE_ROUTE = "/candy/orders/:orderId";

export const CandyOrderPage = () => {
  return (
    <CandyPage fullHeight title="Order" skeleton={<PageSkeleton />}>
      <InnerCandyOrderPage />
    </CandyPage>
  );
};

interface RowProps {
  item: OrderedItem;
  order: Order;
}

const ItemRow = ({ item, order }: RowProps) => {
  const [noPicture, setNoPicture] = useState(false);

  const color = item.confirmed === 0 ? "rgb(255, 244, 229)" : "";

  return (
    <TableRow key={item.id} style={{ backgroundColor: color }}>
      <TableCell padding="none" style={{ backgroundColor: "white" }}>
        <img
          width={80}
          loading="lazy"
          hidden={noPicture}
          onError={() => setNoPicture(true)}
          onLoad={() => setNoPicture(false)}
          src={`https://storage.googleapis.com/candy-pictures/articles/${item.articleId}.jpg`}
        />
      </TableCell>
      <TableCell>
        <MuiLink
          component={Link}
          to={generatePath(CANDY_ARTICLE_PAGE_ROUTE, {
            articleId: item.articleId,
          })}
        >
          {item.articleId}
        </MuiLink>
      </TableCell>
      <TableCell>{item.displayName}</TableCell>
      <TableCell align="right">{item.price}</TableCell>
      <TableCell align="right">{item.ordered}</TableCell>
      <TableCell align="right">{item.confirmed}</TableCell>
      <TableCell align="right">
        {order.delivered ? item.delivered : "-"}
      </TableCell>
    </TableRow>
  );
};

const InnerCandyOrderPage = () => {
  const orderId = useOrderId();
  const { t } = useTranslation();

  const [{ data: orderItems }, { data: orderSummary }] = useSuspenseQueries({
    queries: [
      orderAPI.fetchOrderedItems(orderId),
      orderAPI.fetchOrder(orderId),
    ],
  });

  if (typeof orderSummary === "undefined") {
    return (
      <CandyPage fullHeight title="Order not found">
        <Typography variant="h2">{t("Order not found")}</Typography>
      </CandyPage>
    );
  }

  return (
    <CandyPage title={`Order ${orderSummary.orderId}`}>
      <>
        <Grid container spacing={2} sx={{ mb: 2 }} height={"100px"}>
          <Grid item sm={3}>
            <Card>
              <CardContent>
                <Typography variant="overline" component="h2" gutterBottom>
                  {t("Total cost")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    component="p"
                    color="textSecondary"
                  >
                    {orderSummary.totalPrice} SEK
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={2}>
            <Card>
              <CardContent>
                <Typography variant="overline" component="h2" gutterBottom>
                  {t("Articles")}
                </Typography>
                <Typography variant="body2" component="p" color="textSecondary">
                  {orderSummary.itemCount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={2}>
            <Card>
              <CardContent>
                <Typography variant="overline" component="h2" gutterBottom>
                  {t("Weight")}
                </Typography>
                <Typography variant="body2" component="p" color="textSecondary">
                  {orderSummary.orderWeight}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={2}>
            <Card>
              <CardContent>
                <Typography variant="overline" component="h2" gutterBottom>
                  {t("Ordered")}
                </Typography>
                <Typography variant="body2" component="p" color="textSecondary">
                  <FormattedDate
                    date={orderSummary.ordered}
                    pattern="yyyy-MM-dd"
                  />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={2}>
            <Card>
              <CardContent>
                <Typography variant="overline" component="h2" gutterBottom>
                  {t("Delivered")}
                </Typography>
                <Typography variant="body2" component="p" color="textSecondary">
                  <FormattedDate
                    date={orderSummary.delivered}
                    pattern="yyyy-MM-dd"
                  />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Typography variant="h5">
          {t("Order #{{orderId}}", { orderId })}
        </Typography> */}
        <TableContainer sx={{ flex: 1 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t("Article")}</TableCell>
                <TableCell>{t("Display Name")}</TableCell>
                <TableCell>{t("Price")}</TableCell>
                <TableCell>{t("Ordered")}</TableCell>
                <TableCell>{t("Confirmed")}</TableCell>
                <TableCell>{t("Delivered")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderItems.map((item) => (
                <ItemRow item={item} order={orderSummary} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </CandyPage>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" />
    </>
  );
};
