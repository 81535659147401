import { useSetRecoilState } from "recoil";
import { printState } from "../state/printState";

export const useClearPrintList = () => {
  const setPrintState = useSetRecoilState(printState);

  return () => {
    setPrintState((oldState) => {
      return {
        ...oldState,
        items: [],
      };
    });
  };
};
