import { ArticleId, CandyType, UTCDate } from "./CandyAPI";
import { Page, SortDirection } from "./Page";
import { API, RequiredQueryOptions } from "./network/API";

export interface InventoryItem {
  articleId: ArticleId;
  displayName: string;
  type: CandyType;
  packageSize: number;
  currentStock: number;
  providerStock: number;
  latestOpened?: UTCDate;
  currentExpiry?: UTCDate;
  backInStock?: string; //ISO Date
  storeLocation?: string;
  storageLocation?: string;
}

export interface InventoryOverview {
  totalWorth: number; //double
  uniqueItems: number; //int
  totalWeight: number;
}

export interface InventoryStockHistory {
  id: number;
  articleId: ArticleId;
  created: UTCDate;
  stock: number; //Hur mycket den hade vid de givna tillfället, inte delta
  change: number;
  updatedBy: string;
}

export interface AvailableAtProvider {
  articleId: ArticleId;
  displayName: string;
  providerStock: number;
  arrivedInStock?: string; //yyyy-MM-dd
  candyType?: CandyType;
}

export interface InventoryLog {
  id: number;
  articleId: ArticleId;
  displayName: string;
  created: UTCDate;
  stock: number;
  change: number;
  updatedBy: string;
}

export interface ProviderLog {
  id: number;
  created: UTCDate;
  stock: number;
}

const QUERY_KEY = "inventory";

export const inventoryAPI = {
  QUERY_KEY,
  moveToStore: (articleId: ArticleId, count = 1) =>
    API.post(`/inventory/${articleId}/move-to-store`, {
      count,
    }),

  moveToInventory: (articleId: ArticleId, count = 1) =>
    API.post(`/inventory/${articleId}/add-to-inventory`, {
      count,
    }),

  updateInventory: (articleId: ArticleId, currentStock: number) =>
    API.post(`/inventory/${articleId}`, {
      currentStock,
      // currentExpiry
      // storeLocation
      // storageLocation
    }),

  moveBatchToInventory: (list: { articleId: ArticleId; count: number }[]) =>
    API.post(`/inventory/batch`, list),

  fetchInventory: (
    articleId: ArticleId
  ): RequiredQueryOptions<InventoryItem> => ({
    queryKey: [QUERY_KEY, articleId],
    queryFn: () => API.get<InventoryItem>(`/inventory/${articleId}`),
  }),

  loadInventory: (articleId: ArticleId) =>
    API.get<InventoryItem>(`/inventory/${articleId}`),

  loadNotInInventoryButAvailableWithProvider: (): RequiredQueryOptions<
    AvailableAtProvider[]
  > => ({
    queryKey: [QUERY_KEY, "no-stock"],
    queryFn: () => API.get<AvailableAtProvider[]>(`/inventory/no-stock`),
  }),

  fetchOverview: (): RequiredQueryOptions<InventoryOverview> => ({
    queryKey: [QUERY_KEY, "overview"],
    queryFn: () => API.get<InventoryOverview>(`/inventory/overview`),
  }),

  fetchInventoryList: (
    page: number,
    size: number,
    direction: SortDirection,
    field: string
  ): RequiredQueryOptions<Page<InventoryItem>> => ({
    queryKey: [QUERY_KEY, "list", page, size, direction, field],
    queryFn: () =>
      API.get<Page<InventoryItem>>(
        `/inventory?page=${page}&size=${size}&direction=${direction}&field=${field}`
      ),
  }),

  fetchArticleHistory: (
    articleId: ArticleId
  ): RequiredQueryOptions<InventoryStockHistory[]> => ({
    queryKey: [QUERY_KEY, articleId, "history"],
    queryFn: () =>
      API.get<InventoryStockHistory[]>(`/inventory/${articleId}/stock-history`),
  }),

  loadErtStockHistory: (articleId: ArticleId, from: string, to: string) =>
    API.get<ProviderLog[]>(
      `/inventory/${articleId}/provider-stock-history?from=${from}&to=${to}`
    ),

  fetchInventoryLog: (
    page: number,
    size: number
  ): RequiredQueryOptions<Page<InventoryLog>> => ({
    queryKey: [QUERY_KEY, "log", page, size],
    queryFn: () =>
      API.get<Page<InventoryLog>>(`/inventory/log?page=${page}&size=${size}`),
  }),
};
