import { FunctionComponent, useState } from "react";
import { RemoteId } from "../../../api/AuthAPI";
import { Typography, Button, Box } from "@mui/material";
import { motion } from "framer-motion";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { useTranslation } from "react-i18next";
import {
  CredentialRequestOptionsJSON,
  get,
  parseRequestOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";
import { API } from "../../../api/network/API";

interface Props {
  remoteId?: RemoteId;
  onFinished?: () => void;
}

interface StartAssertion extends CredentialRequestOptionsJSON {
  requestId: string;
}

export const RemoteAuth: FunctionComponent<Props> = ({
  remoteId,
  onFinished,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<"FINISHED" | "PENDING" | "ERROR">(
    "PENDING"
  );

  const [greeting] = useState(
    GREETINGS[Math.floor(Math.random() * GREETINGS.length)]
  );

  const login = () => {
    API.post<StartAssertion>("/auth/login")
      .then(async ({ requestId, ...response }) => {
        const options = parseRequestOptionsFromJSON({ ...response });
        const credential = await get(options);
        await API.post(`/auth/login/token/${remoteId}/confirm`, {
          requestId,
          credential,
        });
        setState("FINISHED");
        setTimeout(() => {
          onFinished?.();
        }, 2000);
      })
      .catch((err) => {
        setState("ERROR");
        console.log(err);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        m: 5,
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          component={motion.div}
          key={state}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 8,
          }}
        >
          <Typography
            initial={{ rotate: 0 }}
            key="state"
            animate={{
              rotate:
                state === "PENDING" ? [0, 15, -15, 15, -15, 15, -15, 0] : 0,
            }}
            transition={{
              repeat: Infinity,
              repeatType: "mirror",
              duration: 10,
            }}
            sx={{
              transformOrigin: state === "PENDING" ? "bottom right" : "center",
            }}
            component={motion.div}
            variant="h2"
            align="center"
            gutterBottom
          >
            {state === "PENDING" ? "👋" : state === "FINISHED" ? "🎉" : "😵"}
          </Typography>
        </Box>
        {state === "FINISHED" && (
          <>
            <Typography
              variant="body1"
              align="center"
              fontWeight={600}
              marginBottom={3}
            >
              {t("Sparkling success!")}
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
              {t("You can now return to your other device.")}
            </Typography>
          </>
        )}
        {state === "PENDING" && (
          <>
            <Typography
              variant="body1"
              align="center"
              fontWeight={600}
              marginBottom={3}
            >
              {greeting.firstPart}
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
              {t("Authenticate to sign in on your other device.")}
            </Typography>
            <Box sx={{ my: 5 }}>
              <Button
                onClick={login}
                size="large"
                variant="contained"
                fullWidth
              >
                {t("Authenticate")} <FingerprintIcon sx={{ ml: 1 }} />
              </Button>
            </Box>
          </>
        )}
        {state === "ERROR" && (
          <>
            <Typography
              variant="body1"
              align="center"
              fontWeight={600}
              marginBottom={3}
            >
              {t("Oops! Something went wrong.")}
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
              {t("Make sure this device is authenticated or try again.")}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const GREETINGS = [
  {
    firstPart: "Unlock a world of sweet delights and productivity.",
    secondPart: "Sign in and let the candy adventures begin!",
  },
  {
    firstPart: "Time to clock in and unwrap some work fun.",
    secondPart: "Sign in and let's make candy magic happen!",
  },
  {
    firstPart: "Ready to sweeten your workday?",
    secondPart: "Sign in and let's whip up some candy-coated success!",
  },
  {
    firstPart: "Get ready to sugar-coat your day!",
    secondPart: "Sign in and let's whip up some candy shop magic together!",
  },
  {
    firstPart: "Unlock the candy vault!",
    secondPart:
      "Sign in and join the sweetest team in town - it's treat o'clock!",
  },
  {
    firstPart: "Candy team, unite!",
    secondPart: "Sign in and let the sweetness begin!",
  },
  {
    firstPart: "Ready to dive into candy paradise?",
    secondPart: "Sign in now and start crafting some sugary moments!",
  },
];
