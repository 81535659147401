import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSuspenseQueries } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { ArticleId } from "../api/CandyAPI";
import { OrderItem, orderAPI } from "../api/OrderAPI";
import { CandyPage } from "../components/CandyPage";
import { MuiLink } from "../components/MuiLink";
import { getCandyUnit } from "../components/UnitPrice";
import { useAddToOrder } from "../hooks/useAddToOrder";
import { useBreakpointDown } from "../hooks/useBreakpoint";
import { useRemoveFromOrder } from "../hooks/useRemoveFromOrder";
import { useSnackBar } from "../hooks/useSnackbar";
import { CANDY_ARTICLE_PAGE_ROUTE } from "./CandyArticlePage/CandyArticlePage";
import { SortDirection } from "../api/Page";
import { SortLabel, getComparator } from "../components/table/SortLabel";
import { BACKEND } from "../api/network/API";

export const CANDY_NEXT_ORDER_PAGE_ROUTE = "/candy/orders/next";

export const CandyNextOrderPage = () => {
  return (
    <CandyPage fullHeight title="Next order" skeleton={<PageSkeleton />}>
      <InnerCandyNextOrderPage />
    </CandyPage>
  );
};

interface NextOrderProp {
  candy: OrderItem;
  updateArticle: (articleId: ArticleId, count: number) => void;
  removeArticle: (articleId: ArticleId) => void;
}

const NextOrderRow = ({
  candy,
  updateArticle,
  removeArticle,
}: NextOrderProp) => {
  const [noPicture, setNoPicture] = useState(false);

  return (
    <TableRow key={candy.articleId}>
      <TableCell
        style={{
          width: "1%",
          paddingLeft: "0",
          paddingRight: "0",
          backgroundColor: `${candy.providerStock > 0 ? "green" : "orange"}`,
        }}
      ></TableCell>
      <TableCell padding="none">
        <img
          width={80}
          loading="lazy"
          hidden={noPicture}
          onError={() => setNoPicture(true)}
          onLoad={() => setNoPicture(false)}
          src={`https://storage.googleapis.com/candy-pictures/articles/${candy.articleId}.jpg`}
        />
      </TableCell>
      <TableCell>
        <MuiLink
          component={Link}
          to={generatePath(CANDY_ARTICLE_PAGE_ROUTE, {
            articleId: candy.articleId,
          })}
        >
          {candy.articleId}
        </MuiLink>
      </TableCell>
      <TableCell>{candy.displayName}</TableCell>
      <TableCell>{(candy.itemPrice * candy.count).toFixed(2)}</TableCell>
      <TableCell align="right">
        {(candy.packageSize * candy.count).toLocaleString("sv-SE", {
          maximumFractionDigits: 1,
        })}
        {getCandyUnit(candy.candyType)}
      </TableCell>
      <TableCell align="right">
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton
            onClick={() => updateArticle(candy.articleId, candy.count - 1)}
            color="secondary"
          >
            <RemoveIcon />
          </IconButton>
          <Box>{candy.count}</Box>
          <IconButton
            onClick={() => updateArticle(candy.articleId, candy.count + 1)}
            color="secondary"
          >
            <AddIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => removeArticle(candy.articleId)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const InnerCandyNextOrderPage = () => {
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const isMobile = useBreakpointDown("sm");
  const [{ data: items }, { data: overview }] = useSuspenseQueries({
    queries: [orderAPI.fetchNextOrder(), orderAPI.fetchPendingOrderOverview()],
  });

  const { mutate: addToOrder } = useAddToOrder();
  const { mutate: removeFromOrder } = useRemoveFromOrder();

  const [order, setOrder] = useState<SortDirection>(SortDirection.ASC);
  const [orderBy, setOrderBy] = useState<keyof OrderItem | "price">(
    "articleId"
  );

  const updateSort = (property: keyof OrderItem | "price") => {
    const isAsc = orderBy === property && order === SortDirection.ASC;
    setOrder(isAsc ? SortDirection.DESC : SortDirection.ASC);
    setOrderBy(property);
  };

  const sortedItems = useMemo(() => {
    return items.sort((a, b) => {
      if (orderBy === "price") {
        return getComparator(order, orderBy)(
          { price: a.itemPrice * a.count },
          { price: b.itemPrice * b.count }
        );
      }
      return getComparator(order, orderBy)(a, b);
    });
  }, [items, order, orderBy]);

  const updateArticle = useCallback(
    (articleId: ArticleId, count: number) => {
      if (count === 0) {
        return removeFromOrder(articleId, {
          onSuccess: () => {
            showSnackBar(
              t("Removed {{article}} from next order!", { article: articleId }),
              "success"
            );
          },
          onError: () => {
            showSnackBar(
              t("Failed to remove {{article}}", { article: articleId }),
              "error"
            );
          },
        });
      }

      addToOrder(
        {
          articleId,
          count,
        },
        {
          onSuccess: () => {
            showSnackBar(
              t("Updated quantity of {{article}} on next order!", {
                article: articleId,
              }),
              "info"
            );
          },
          onError: () => {
            showSnackBar(
              t("Failed to update quantity of {{article}}", {
                article: articleId,
              }),
              "error"
            );
          },
        }
      );
    },
    [addToOrder, removeFromOrder, showSnackBar, t]
  );

  return (
    <>
      {!isMobile && (
        <Grid container spacing={2} sx={{ mb: 2 }} height={"100px"}>
          <Grid item sm={3}>
            <Card>
              <CardContent>
                <Typography variant="overline" component="h2" gutterBottom>
                  {t("Total cost")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    component="p"
                    color="textSecondary"
                  >
                    {overview?.totalPrice ?? "-"} SEK
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={2}>
            <Card>
              <CardContent>
                <Typography variant="overline" component="h2" gutterBottom>
                  {t("Articles")}
                </Typography>
                <Typography variant="body2" component="p" color="textSecondary">
                  {overview?.totalArticles ?? "-"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={2}>
            <Card>
              <CardContent>
                <Typography variant="overline" component="h2" gutterBottom>
                  {t("Boxes")}
                </Typography>
                <Typography variant="body2" component="p" color="textSecondary">
                  {overview?.totalBoxes ?? "-"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={2}>
            <Card>
              <CardContent>
                <Typography variant="overline" component="h2" gutterBottom>
                  {t("Weight")}
                </Typography>
                <Typography variant="body2" component="p" color="textSecondary">
                  {overview?.totalWeight ?? "-"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={3}>
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CardContent>
                <Button
                  size="large"
                  variant="contained"
                  href={BACKEND + "/orders/pending/export"}
                  download
                >
                  Download
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <SortLabel
                label={t("Article Id")}
                sortKey="articleId"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Display Name")}
                sortKey="displayName"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Price")}
                sortKey="price"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <TableCell align="right">{t("Order size")}</TableCell>
              <SortLabel
                label={t("Quantity")}
                sortKey="count"
                direction={order}
                orderBy={orderBy}
                align="right"
                updateSort={updateSort}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems.map((candy) => (
              <NextOrderRow
                key={candy.articleId}
                candy={candy}
                updateArticle={updateArticle}
                removeArticle={(articleId) => updateArticle(articleId, 0)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(10)].map((_, i) => (
          <Grid item xs={12} key={i}>
            <Skeleton variant="rounded" height={60} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
