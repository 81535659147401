import { Button, Grid, SxProps } from "@mui/material";
import { Html5QrcodeResult } from "html5-qrcode";
import { FunctionComponent } from "react";
import { randomEAN } from "../utils";

interface Props {
  onScanSuccess: (
    decodedText: string,
    decodedResult: Html5QrcodeResult
  ) => void;
  fixedEan?: string;
  sx?: SxProps;
  position?: "top" | "bottom";
}

export const DevButtons: FunctionComponent<Props> = ({
  onScanSuccess,
  fixedEan,
  sx,
  position = "top",
}) => {
  if (import.meta.env.PROD) {
    return null;
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{
        left: 0,
        right: 0,
        zIndex: 1000,
        p: 2,
        position: "absolute",
        [position]: 0,
        ...sx,
      }}
    >
      <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            const ean = fixedEan ?? randomEAN();
            return onScanSuccess(ean, {
              decodedText: ean,
              result: {
                text: ean,
              },
            });
          }}
        >
          {fixedEan ? "Fixed EAN" : "Random EAN"}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          onClick={() =>
            onScanSuccess("6420256436969", {
              decodedText: "6420256436969",
              result: {
                text: "6420256436969",
              },
            })
          }
        >
          Invalid EAN
        </Button>
      </Grid>
      {/* <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          onClick={() =>
            onScanSuccess("5704813000701", {
              decodedText: "5704813000701",
              result: {
                text: "5704813000701",
              },
            })
          }
        >
          Disabled article
        </Button>
      </Grid> */}
      {/* <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          onClick={() =>
            onScanSuccess("7330869155928", {
              decodedText: "7330869155928",
              result: {
                text: "7330869155928",
              },
            })
          }
        >
          Gott & Blandat
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          onClick={() =>
            onScanSuccess("7330869155669", {
              decodedText: "7330869155669",
              result: {
                text: "7330869155669",
              },
            })
          }
        >
          Ahlgrens
        </Button>
      </Grid> */}
    </Grid>
  );
};
