import { CandyType } from "../api/CandyAPI";

interface Props {
  price?: number;
  candyType?: CandyType;
}

export enum CandyUnit {
  KILO = " kg",
  PIECE = "",
}

export const getCandyUnit = (candyType?: CandyType): CandyUnit =>
  candyType === CandyType.CANDY_PIECE || candyType === CandyType.CHIPS
    ? CandyUnit.PIECE
    : CandyUnit.KILO;

export const UnitPrice = ({ price = 0, candyType }: Props) => {
  return (
    <>
      {price}/{getCandyUnit(candyType)}
    </>
  );
};
