import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link, generatePath } from "react-router-dom";
import { CandyPage } from "../components/CandyPage";
import { orderAPI } from "../api/OrderAPI";
import { FormattedDate } from "../components/date/FormattedDate";
import { MuiButtonLink, MuiLink } from "../components/MuiLink";
import { useTranslation } from "react-i18next";
import { CANDY_ORDER_RECONCILIATION_PAGE_ROUTE } from "./CandyOrderReconciliationPage/CandyOrderReconciliationPage";
import { useSuspenseQuery } from "@tanstack/react-query";
import { CANDY_ORDER_PAGE_ROUTE } from "./CandyOrderPage";

export const CANDY_ORDERS_PAGE_ROUTE = "/candy/orders";

export const CandyOrdersPage = () => {
  return (
    <CandyPage fullHeight title="Orders" skeleton={<PageSkeleton />}>
      <InnerCandyOrdersPage />
    </CandyPage>
  );
};

const InnerCandyOrdersPage = () => {
  const { t } = useTranslation();

  const { data: orders } = useSuspenseQuery(orderAPI.fetchOrders());

  return (
    <>
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t("Order Reference")}</TableCell>
              <TableCell>{t("# of Articles")}</TableCell>
              <TableCell>{t("Total weight")}</TableCell>
              <TableCell>{t("Total price")}</TableCell>
              <TableCell>{t("Sent")}</TableCell>
              <TableCell>{t("Delivered")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.orderId}>
                <TableCell>
                  <MuiLink
                    component={Link}
                    to={generatePath(CANDY_ORDER_PAGE_ROUTE, {
                      orderId: order.orderId,
                    })}
                  >
                    {order.externalOrderReference}
                  </MuiLink>
                </TableCell>
                <TableCell>{order.itemCount}</TableCell>
                <TableCell>{order.orderWeight} kg</TableCell>
                <TableCell>
                  {order.totalPrice + order.shippingCost} SEK
                </TableCell>
                <TableCell>
                  <FormattedDate date={order.ordered} />
                </TableCell>
                <TableCell>
                  {order.delivered ? (
                    <FormattedDate date={order.delivered} />
                  ) : (
                    <MuiButtonLink
                      to={generatePath(CANDY_ORDER_RECONCILIATION_PAGE_ROUTE, {
                        orderId: order.orderId,
                      })}
                      color="inherit"
                      variant="outlined"
                    >
                      {t("Reconciliate")}
                    </MuiButtonLink>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(10)].map((_, i) => (
          <Grid item xs={12} key={i}>
            <Skeleton variant="rounded" height={50} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
