import { RouterProvider } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { RecoilRoot } from "recoil";
import { SnackBarProvider } from "./state/SnackBarContext";
import { ThemeContextProvider } from "./state/ThemeContext";
import { LoadingOverlay } from "./components/LoadingOverlay";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { router } from "./routes/router";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "always",
    },
  },
});

const App = () => {
  return (
    <ThemeContextProvider>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <CssBaseline />
          <SnackBarProvider>
            <LoadingOverlay>
              <RouterProvider router={router} />
            </LoadingOverlay>
          </SnackBarProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </ThemeContextProvider>
  );
};

export default App;
