import { API } from "../../api/network/API";
import { CandyPage } from "../../components/CandyPage";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import {
  parseRequestOptionsFromJSON,
  get,
} from "@github/webauthn-json/browser-ponyfill";
import { CredentialRequestOptionsJSON } from "@github/webauthn-json";
import { Box, Button, Skeleton, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useMutation, useQuery } from "@tanstack/react-query";
import { RemoteLoginStatus, authAPI } from "../../api/AuthAPI";
import { QRCode } from "react-qrcode-logo";
import { AUTHENTICATE_PAGE_ROUTE } from "./AuthenticatePage";

export const LOGIN_PAGE_ROUTE = "/login";

const GREETINGS = [
  {
    firstPart: "Unlock a world of sweet delights and productivity.",
    secondPart: "Sign in and let the candy adventures begin!",
  },
  {
    firstPart: "Time to clock in and unwrap some work fun.",
    secondPart: "Sign in and let's make candy magic happen!",
  },
  {
    firstPart: "Ready to sweeten your workday?",
    secondPart: "Sign in and let's whip up some candy-coated success!",
  },
  {
    firstPart: "Get ready to sugar-coat your day!",
    secondPart: "Sign in and let's whip up some candy shop magic together!",
  },
  {
    firstPart: "Unlock the candy vault!",
    secondPart:
      "Sign in and join the sweetest team in town - it's treat o'clock!",
  },
  {
    firstPart: "Candy team, unite!",
    secondPart: "Sign in and let the sweetness begin!",
  },
  {
    firstPart: "Ready to dive into candy paradise?",
    secondPart: "Sign in now and start crafting some sugary moments!",
  },
];

interface StartAssertion extends CredentialRequestOptionsJSON {
  requestId: string;
}

export const LoginPage = () => {
  const [greeting] = useState(
    GREETINGS[Math.floor(Math.random() * GREETINGS.length)]
  );

  return (
    <CandyPage fullHeight title="Login">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          m: 5,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            component={motion.div}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 100,
              damping: 8,
            }}
          >
            <Typography
              initial={{ rotate: 0 }}
              animate={{
                rotate: [0, 15, -15, 15, -15, 15, -15, 0],
              }}
              transition={{
                repeat: Infinity,
                repeatType: "mirror",
                duration: 10,
              }}
              sx={{
                transformOrigin: "bottom right",
              }}
              component={motion.div}
              variant="h2"
              align="center"
              gutterBottom
            >
              👋
            </Typography>
          </Box>
          <Typography
            variant="body1"
            align="center"
            fontWeight={600}
            marginBottom={3}
          >
            {greeting.firstPart}
          </Typography>
          <Typography variant="body2" align="center" color="textSecondary">
            {greeting.secondPart}
          </Typography>
          <Box sx={{ my: 5 }}>
            {isMobile ? <LoginViewMobile /> : <LoginViewDesktop />}
          </Box>
        </Box>
      </Box>
    </CandyPage>
  );
};

const POLLING_INTERVAL = 2500;

const LoginViewDesktop = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { palette } = useTheme();

  const { data, mutate: initRemoteId } = useMutation({
    mutationFn: authAPI.initRemoteLoginSession,
  });

  const { data: result } = useQuery({
    ...(data?.remoteId
      ? authAPI.fetchRemoteLoginSessionStatus(data.remoteId)
      : { queryKey: [] }),
    enabled: typeof data?.remoteId !== "undefined",
    refetchInterval: POLLING_INTERVAL,
  });

  useEffect(() => {
    if (result?.status === RemoteLoginStatus.CONFIRMED) {
      navigate("/");
    }
  }, [result, navigate]);

  const url = useMemo(() => {
    if (!data?.remoteId) {
      return undefined;
    }
    return (
      window.location.origin +
      generatePath(AUTHENTICATE_PAGE_ROUTE, {
        remoteId: data.remoteId,
      })
    );
  }, [data]);

  useEffect(() => initRemoteId(), [initRemoteId]);

  // for debugging
  useEffect(() => {
    if (!url) return;
    console.info("Authentication url: ", url);
  }, [url]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {data?.remoteId ? (
        <>
          <QRCode
            qrStyle="dots"
            value={url}
            size={300}
            fgColor={palette.text.primary}
            bgColor="transparent"
            eyeColor={{
              inner: palette.text.primary,
              outer: palette.secondary.light,
            }}
            eyeRadius={[
              [30, 30, 10, 30],
              [30, 30, 30, 10],
              [30, 10, 30, 30],
            ]}
          />

          <Typography
            variant="overline"
            align="center"
            gutterBottom
            sx={{ mt: 4 }}
          >
            {t(
              "Please scan the QR code above on your authenticated phone to sign in"
            )}
          </Typography>

          {/* <MuiLink
            align="center"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
            sx={{ fontSize: "0.8rem", mt: 4 }}
          >
            {url}
          </MuiLink> */}
        </>
      ) : (
        <Skeleton height={400} width={400} variant="rounded" />
      )}
    </Box>
  );
};

const LoginViewMobile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);

  const login = () => {
    setError(false);
    API.post<StartAssertion>("/auth/login")
      .then(async ({ requestId, ...response }) => {
        const options = parseRequestOptionsFromJSON({ ...response });
        const credential = await get(options);
        await API.post("/auth/login/finish", {
          requestId,
          credential,
        });
        navigate("/");
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  return (
    <>
      <Button onClick={login} size="large" variant="contained" fullWidth>
        {t("sign in")} <FingerprintIcon sx={{ ml: 1 }} />
      </Button>
      <AnimatePresence>
        {error && (
          <Typography
            align="center"
            variant="body2"
            color="error"
            sx={{ mt: 2 }}
            component={motion.div}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
          >
            {t("That didn't work out... please try again.")}
          </Typography>
        )}
      </AnimatePresence>
    </>
  );
};
