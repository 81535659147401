import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { FunctionComponent } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import TranslateIcon from "@mui/icons-material/Translate";
import { CANDY_ORDERS_PAGE_ROUTE } from "../pages/CandyOrdersPage";
import { CANDY_NEXT_ORDER_PAGE_ROUTE } from "../pages/CandyNextOrderPage";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import InventoryIcon from "@mui/icons-material/Inventory";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Link, useLocation } from "react-router-dom";
import { CANDY_PICK_PAGE_ROUTE } from "../pages/CandyPickPage";
import { CANDY_STOCK_SCAN_PAGE } from "../pages/CandyStockScanPage";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import { CANDY_REMOVE_STOCK_SCAN_PAGE } from "../pages/CandyRemoveStockScanPage/CandyRemoveStockScanPage";
import { INVENTORY_PAGE_ROUTE } from "../pages/CandyInventoryPage";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { LanguageSelect } from "./LanguageSelect";
import { TRANSLATE_PAGE_ROUTE } from "../pages/TranslatePage";
import { isMobile } from "react-device-detect";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import logo from "../assets/logo-1.png";
import { UserInfo } from "./UserInfo";
import { useBreakpointDown } from "../hooks/useBreakpoint";
import { WEBSHOP_PRODUCTS_PAGE_ROUTE } from "../pages/Webshop/WebshopProductsPage";
import { INVENTORY_LOG_ROUTE } from "../pages/Inventory/InventoryLogPage";
import { INVENTORY_NO_STOCK_PAGE_ROUTE } from "../pages/Inventory/CandyInventoryNoStockPage";
import { PIECE_PRICE_PAGE_ROUTE } from "../pages/PiecePricesPage/PiecePricesPage";

export const DRAWER_WIDTH = 240;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isNarrow?: boolean;
}

export const CandySidebar: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  isNarrow,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const isMobile = useBreakpointDown("sm");

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
        },
      }}
      variant={isNarrow ? "temporary" : "permanent"}
      anchor="left"
      open={isOpen}
      onClose={onClose}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          textDecoration: "none",
          color: "inherit",
          transition: (theme) => theme.transitions.create("background-color"),
          "&:hover": {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        }}
        component={Link}
        to="/"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box component="img" src={logo} alt="logo" height={"2em"} />
          <Typography variant="overline" noWrap fontWeight={800} lineHeight={1}>
            Rainbow Candy
          </Typography>
        </Box>

        {isNarrow && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
      <Divider />
      <List>
        {LINKS(t).map(({ key, label, icon, matchFn }) => (
          <ListItem
            key={key}
            disablePadding
            onClick={() => {
              onClose();
            }}
          >
            <ListItemButton
              selected={
                matchFn?.(location.pathname) || key === location.pathname
              }
              LinkComponent={Link}
              {...{
                to: key,
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {LINKS_LOWER(t).map(({ key, label, icon, matchFn }) => (
          <ListItem
            key={key}
            disablePadding
            onClick={() => {
              onClose();
            }}
          >
            <ListItemButton
              selected={
                matchFn?.(location.pathname) || key === location.pathname
              }
              LinkComponent={Link}
              {...{
                to: key,
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box
        sx={{
          marginTop: "auto",
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {isMobile && (
          <Box marginTop={2}>
            <Typography gutterBottom variant="overline" color="textSecondary">
              {t("User")}
            </Typography>
            <Box>
              <UserInfo fullWidth variant="contained" />
            </Box>
          </Box>
        )}
        <Box>
          <Typography gutterBottom variant="overline" color="textSecondary">
            {t("Language")}
          </Typography>
          <LanguageSelect />
        </Box>
      </Box>
    </Drawer>
  );
};

enum DisplayFilter {
  DESKTOP,
  MOBILE,
  ALL,
}

interface LinkItem {
  key: string;
  label: string;
  icon: JSX.Element;
  filter: DisplayFilter;
  matchFn?: (pathname: string) => boolean;
}

const LINKS = (t: TFunction): LinkItem[] =>
  [
    {
      key: "/",
      label: t("Articles"),
      icon: <FormatListBulletedIcon />,
      filter: DisplayFilter.ALL,
    },
    {
      key: INVENTORY_PAGE_ROUTE,
      label: "Inventory",
      icon: <InventoryIcon />,
      filter: DisplayFilter.ALL,
    },
    {
      key: INVENTORY_LOG_ROUTE,
      label: "Inventory log",
      icon: <WorkHistoryIcon />,
      filter: DisplayFilter.DESKTOP,
    },
    {
      key: INVENTORY_NO_STOCK_PAGE_ROUTE,
      label: "Available In stock",
      icon: <WarehouseIcon />,
      filter: DisplayFilter.DESKTOP,
    },
    {
      key: WEBSHOP_PRODUCTS_PAGE_ROUTE,
      label: "Webshop Inventory",
      icon: <ShoppingCartIcon />,
      filter: DisplayFilter.DESKTOP,
    },
    {
      key: PIECE_PRICE_PAGE_ROUTE,
      label: "Piece Prices",
      icon: <PointOfSaleIcon />,
      filter: DisplayFilter.DESKTOP,
    },
    {
      key: CANDY_ORDERS_PAGE_ROUTE,
      label: t("Orders"),
      icon: <LocalShippingIcon />,
      filter: DisplayFilter.ALL,
    },
    {
      key: CANDY_NEXT_ORDER_PAGE_ROUTE,
      label: t("Next Order"),
      icon: <PendingActionsIcon />,
      filter: DisplayFilter.ALL,
    },
    {
      key: CANDY_PICK_PAGE_ROUTE,
      label: t("Pick List"),
      icon: <FormatListNumberedIcon />,
      filter: DisplayFilter.MOBILE,
    },
    {
      key: CANDY_STOCK_SCAN_PAGE,
      label: t("Add to stock"),
      icon: <AddToPhotosIcon />,
      filter: DisplayFilter.MOBILE,
    },
    {
      key: CANDY_REMOVE_STOCK_SCAN_PAGE,
      label: t("Remove from stock"),
      icon: <PlaylistRemoveIcon />,
      filter: DisplayFilter.MOBILE,
    },
  ].filter(({ filter }) => {
    if (filter === DisplayFilter.ALL) {
      return true;
    }
    if (isMobile) {
      return filter === DisplayFilter.MOBILE;
    }
    return filter === DisplayFilter.DESKTOP;
  });

const LINKS_LOWER = (t: TFunction): LinkItem[] =>
  [
    {
      key: "/labels",
      label: t("Print Labels"),
      icon: <LocalPrintshopIcon />,
      filter: DisplayFilter.ALL,
    },
    {
      key: TRANSLATE_PAGE_ROUTE,
      label: t("Translate"),
      icon: <TranslateIcon />,
      filter: DisplayFilter.ALL,
      matchFn: (pathname: string) => pathname.startsWith(TRANSLATE_PAGE_ROUTE),
    },
  ].filter(({ filter }) => {
    if (filter === DisplayFilter.ALL) {
      return true;
    }
    if (isMobile) {
      return filter === DisplayFilter.MOBILE;
    }
    return filter === DisplayFilter.DESKTOP;
  });
