import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import { inventoryAPI } from "../../api/InventoryAPI";
import { CandyPage } from "../../components/CandyPage";
import { MuiLink } from "../../components/MuiLink";
import { FormattedDate } from "../../components/date/FormattedDate";
import { CANDY_ARTICLE_PAGE_ROUTE } from "../CandyArticlePage/CandyArticlePage";
import { useSuspenseQuery } from "@tanstack/react-query";
import { startTransition } from "react";

const DEFAULT_PAGE_SIZE = 25;

export const INVENTORY_LOG_ROUTE = "/inventory/log";

export const InventoryLogPage: React.FunctionComponent = () => {
  return (
    <CandyPage
      fullHeight
      title="Inventory - Log history"
      skeleton={<PageSkeleton />}
    >
      <InnerInventoryPage />
    </CandyPage>
  );
};

const InnerInventoryPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page") || "0";
  const size = searchParams.get("size") || DEFAULT_PAGE_SIZE.toString();

  const { data: result } = useSuspenseQuery(
    inventoryAPI.fetchInventoryLog(parseInt(page), parseInt(size))
  );

  return (
    <>
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t("Display Name")}</TableCell>
              <TableCell>{t("Change")}</TableCell>
              <TableCell>{t("Change by")}</TableCell>
              <TableCell align="right">{t("Change")}</TableCell>
              <TableCell align="right">{t("Stock After")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result?.content?.map((log) => (
              <TableRow key={log.id}>
                <TableCell>
                  <MuiLink
                    component={Link}
                    to={generatePath(CANDY_ARTICLE_PAGE_ROUTE, {
                      articleId: log.articleId,
                    })}
                  >
                    {log.displayName}
                  </MuiLink>
                </TableCell>
                <TableCell>
                  <FormattedDate date={log.created} />{" "}
                </TableCell>
                <TableCell>{log.updatedBy}</TableCell>
                <TableCell align="right">
                  {log.change > 0 ? `+${log.change}` : log.change}
                </TableCell>
                <TableCell align="right">{log.stock}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={result?.totalElements || 0}
        rowsPerPage={result?.size || DEFAULT_PAGE_SIZE}
        page={result?.number || 0}
        onPageChange={(_, page) => {
          startTransition(() => {
            setSearchParams({
              size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
              page: page.toString(),
            });
          });
        }}
        onRowsPerPageChange={(event) => {
          startTransition(() => {
            setSearchParams({
              page: searchParams.get("page") || "0",
              size: event.target.value,
            });
          });
        }}
      />
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(20)].map((_, i) => (
          <Grid item xs={12} key={i}>
            <Skeleton variant="rounded" height={50} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
