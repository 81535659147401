import { FunctionComponent, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { settingsState } from "../state/settingsState";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CameraDevice, Html5Qrcode } from "html5-qrcode";
import SettingsIcon from "@mui/icons-material/Settings";

export const CameraSelect: FunctionComponent = () => {
  const [state, setState] = useRecoilState(settingsState);
  const [isOpen, setIsOpen] = useState(false);
  const [devices, setDevices] = useState<CameraDevice[]>([]);
  const { t } = useTranslation();

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    Html5Qrcode.getCameras().then(setDevices);
  }, []);

  return (
    <>
      <Fab
        onClick={() => {
          setIsOpen(true);
        }}
        variant="circular"
        size="small"
        color="inherit"
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 1000,
          background: "rgba(0, 0, 0, 0.25)",
          color: "white",
        }}
      >
        <SettingsIcon />
      </Fab>

      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>{t("Select camera")}</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
          }}
        >
          <FormControl>
            <RadioGroup
              value={state.cameraDeviceId || ""}
              onChange={(_, value) => {
                setState((state) => ({
                  ...state,
                  cameraDeviceId: value || undefined,
                }));
              }}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value={""}
                control={<Radio />}
                label={t("auto")}
              />
              {devices.map((device) => (
                <FormControlLabel
                  value={device.id}
                  control={<Radio />}
                  label={device.label}
                  key={device.id}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} fullWidth color="inherit">
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
