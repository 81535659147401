import { useMemo } from "react";

const canVibrate = window.navigator.vibrate as unknown as boolean;

export const useFeedback = () => {
  return useMemo(
    () => () => {
      new Audio("/blip.mp3").play();
      if (canVibrate) {
        navigator.vibrate(100);
      }
    },
    []
  );
};
