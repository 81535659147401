import { AtomEffect, atom, selector } from "recoil";
import { PickItem } from "../../../state/pickState";

const localStorageEffect =
  (key: string): AtomEffect<StockList> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: StockList, _, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

const updateTimestamps =
  (): AtomEffect<StockList> =>
  ({ setSelf, onSet }) => {
    onSet((newValue: StockList) => {
      if (newValue.items.length === 0) {
        setSelf({
          ...newValue,
          lastUpdated: undefined,
        });
      }
      setSelf({
        ...newValue,
        lastUpdated: new Date().toISOString(),
      });
    });
  };

interface StockList {
  lastUpdated: string | undefined;
  items: PickItem[];
}

const defaultState = (): StockList => ({
  lastUpdated: undefined,
  items: [],
});

const STATE_VERSION = "1";
const STATE_KEY = `remove-stock-state-${STATE_VERSION}`;

export const removeStockState = atom<StockList>({
  key: STATE_KEY,
  default:
    (localStorage.getItem(STATE_KEY) as unknown as StockList) || defaultState(),
  effects: [updateTimestamps(), localStorageEffect(STATE_KEY)],
});

export const removeStockListCountSelector = selector({
  key: "removeStockListCountSelector",
  get: ({ get }) => {
    const sum = get(removeStockState).items.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    return Math.abs(sum);
  },
});
