import { Box, TableCell, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { SortDirection } from "../../api/Page";

export interface SortLabelProps<T> {
  label: string;
  sortKey: keyof T;
  orderBy: string;
  direction: SortDirection;
  updateSort: (property: keyof T) => void;
  align?: "inherit" | "left" | "center" | "right" | "justify";
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export function getComparator<Key extends keyof any>(
  order: SortDirection,
  orderBy: Key
): (a: any, b: any) => number {
  return order === "DESC"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const SortLabel = <T,>({
  label,
  sortKey,
  orderBy,
  direction,
  updateSort,
  align = "inherit",
}: SortLabelProps<T>) => {
  const internalDirection = direction === SortDirection.ASC ? "asc" : "desc";
  return (
    <TableCell
      align={align}
      sortDirection={orderBy === sortKey ? internalDirection : false}
    >
      <TableSortLabel
        active={orderBy === sortKey}
        direction={orderBy === sortKey ? internalDirection : "asc"}
        onClick={() => updateSort(sortKey)}
      >
        {label}
        {orderBy === sortKey ? (
          <Box component="span" sx={visuallyHidden}>
            {direction === SortDirection.DESC
              ? "sorted descending"
              : "sorted ascending"}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
};
