import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Fade, Grid, SwipeableDrawer, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSnackBar } from "../hooks/useSnackbar";
import { useRemoveFromPickList } from "../hooks/useRemoveFromPickList";
import { PickItem } from "../state/pickState";
import { Scanner } from "./Scanner";
import { Html5QrcodeResult } from "html5-qrcode";
import { useFeedback } from "../hooks/useFeedback";
import { DevButtons } from "./DevButtons";
import { inventoryAPI } from "../api/InventoryAPI";
import { useTranslation } from "react-i18next";
import { useOptionalArticle } from "../hooks/useArticle";
import { MuiButtonLink } from "./MuiLink";
import { generatePath } from "react-router";
import { CANDY_ARTICLE_PAGE_ROUTE } from "../pages/CandyArticlePage/CandyArticlePage";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ScaleIcon from "@mui/icons-material/Scale";

const TIMEOUT = 2000;

interface Props {
  item: PickItem | undefined;
  onClose: () => void;
}

const Puller = () => {
  return (
    <Box
      sx={{
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
      }}
    />
  );
};

const drawerBleeding = 56;

export const CandyPickSheet: FunctionComponent<Props> = ({ item, onClose }) => {
  const isOpen = typeof item !== "undefined";

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={() => {
        // do nothing
      }}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Puller />
        <Box sx={{ p: 2 }}>
          <InnerSheet {...{ item, onClose }} />
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

const InnerSheet: FunctionComponent<Props> = ({ item }) => {
  const removeFromPickList = useRemoveFromPickList();
  const [throttled, setThrottled] = useState(false);
  const { showSnackBar } = useSnackBar();
  const giveFeedback = useFeedback();
  const [status, setStatus] = useState<"success" | "error">();
  const { t } = useTranslation();

  const { data: article } = useOptionalArticle(item?.articleId);

  const paused = useMemo(
    () => typeof item === "undefined" || throttled,
    [item, throttled]
  );

  useEffect(() => {
    if (!throttled) {
      setStatus(undefined);
    }
  }, [throttled]);

  const onPick = useCallback(() => {
    if (!item) {
      return;
    }
    setThrottled(true);

    // TODO: Do /inventory/{articleId}/move-to-store
    inventoryAPI
      .moveToStore(item.articleId)
      .then(() => {
        setStatus("success");
        if (item.quantity === 1) {
          showSnackBar(
            t("All {{article}} picked!", { article: item.articleId }),
            "success"
          );
        }
        removeFromPickList(item.articleId);
      })
      .catch(() => {
        showSnackBar(
          t("Error updating inventory of {{article}} please try again", {
            article: item.articleId,
          }),
          "error"
        );
      })
      .finally(() => {
        setTimeout(() => {
          setThrottled(false);
        }, TIMEOUT);
      });
  }, [item, removeFromPickList, showSnackBar, t]);

  const onScan = useCallback(
    (decodedText: string, decodedResult: Html5QrcodeResult) => {
      console.info(`Code matched = ${decodedText}`, decodedResult);
      if (!paused) {
        if (decodedText !== item?.ean) {
          setThrottled(true);
          setStatus("error");
          setTimeout(() => {
            setThrottled(false);
          }, TIMEOUT);
          return;
        }

        onPick();
        giveFeedback();
      }
    },
    [item, paused, onPick, giveFeedback]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        pt: 1,
      }}
    >
      <Grid container spacing={2}>
        {item && (
          <>
            <Grid item xs={12}>
              <Typography variant="h5" component="h1" align="center">
                <Box
                  component={"span"}
                  sx={{
                    fontWeight: 700,
                  }}
                  color={"secondary.main"}
                >
                  {item?.quantity} &#215;
                </Box>{" "}
                {item?.displayName}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="overline"
                align="center"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <QrCodeIcon sx={{ mr: 1 }} fontSize="small" />
                {item?.ean}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="overline"
                align="center"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <ScaleIcon sx={{ mr: 1 }} fontSize="small" />
                {article?.packageSize
                  ? `${article.packageSize}kg`
                  : t("unknown package size")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MuiButtonLink
                type="button"
                fullWidth
                size="medium"
                to={generatePath(CANDY_ARTICLE_PAGE_ROUTE, {
                  articleId: item.articleId,
                })}
              >
                {t("View more info")}
              </MuiButtonLink>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Scanner
            onScanError={() => {
              // do nothing
            }}
            onScanSuccess={(...props) => {
              onScan(...props);
            }}
            paused={paused}
            aspectRatio={1}
            style={{
              objectFit: "contain",
              minWidth: 200,
              minHeight: 200,
              height: "100%",
              width: "100%",
              aspectRatio: "1.5/1",
              display: "flex",
              margin: "0 auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </Grid>
      </Grid>

      {item && (
        <DevButtons
          onScanSuccess={onScan}
          fixedEan={item.ean}
          position="bottom"
        />
      )}
      <FeedbackOverlay status={status} />
    </Box>
  );
};

const FeedbackOverlay = ({ status }: { status?: "success" | "error" }) => {
  const lastStatus = useRef<"success" | "error">();
  const { t } = useTranslation();

  useEffect(() => {
    if (status) {
      lastStatus.current = status;
    }
  }, [status]);

  return (
    <Fade in={!!status} unmountOnExit>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          zIndex: 200,
          p: 4,
          backgroundColor: (theme) =>
            lastStatus.current === "success"
              ? theme.palette.success.light
              : lastStatus.current === "error"
              ? theme.palette.error.light
              : undefined,
          color: (theme) =>
            lastStatus.current === "success"
              ? theme.palette.success.contrastText
              : lastStatus.current === "error"
              ? theme.palette.error.contrastText
              : undefined,
        }}
      >
        <Typography variant="h3" sx={{ mb: 4 }}>
          {status === "success" && "🎉"}
          {status === "error" && "😢"}
        </Typography>
        <Typography variant="h5" align="center">
          {status === "success" && t("Picked article")}
          {status === "error" && t("Oh no, that is not the correct article")}
        </Typography>
      </Box>
    </Fade>
  );
};
