import { Image, Text, View } from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";
import { FunctionComponent, ReactElement } from "react";
import { CandyArticle, Nutrients } from "../../api/CandyAPI";
import rainbowHeader from "./header.png";

export interface CandyLabel {
  candy: CandyArticle;
  translations: {
    productName: string;
    ingredients: string;
  };
}

function existsAndIsNotZero(value: string | undefined) {
  return value !== undefined && value !== "0.0";
}

function trimZero(value: string | undefined) {
  if (value === undefined) {
    return "";
  }
  return value.replace(".0", "");
}

function printNutrients(nutrition: Nutrients) {
  const energy = `Enerģētiskā vērtība ${trimZero(nutrition.kj)} kJ / ${trimZero(
    nutrition.kcal
  )} kcal`;
  const fat = nutrition.fat ? `Tauki ${trimZero(nutrition.fat)} g` : "";
  const saturatedFat = nutrition.satFat
    ? `tostarp piesātinātās taukskābes ${trimZero(nutrition.satFat)} g`
    : "";

  const carbs = nutrition.carbs
    ? `Ogļhidrāti ${trimZero(nutrition.carbs)} g`
    : "";
  const sugar = nutrition.sugar
    ? `tostarp cukuri ${trimZero(nutrition.sugar)} g`
    : "";
  const starch = existsAndIsNotZero(nutrition.starch)
    ? `tostarp ciete ${trimZero(nutrition.starch)}`
    : "";
  const polyoler = existsAndIsNotZero(nutrition.polyoler)
    ? `tostarp poliolers ${trimZero(nutrition.polyoler)}`
    : "";
  const protien = existsAndIsNotZero(nutrition.protein)
    ? `Olbaltumvielas ${trimZero(nutrition.protein)} g`
    : "";
  const salt = existsAndIsNotZero(nutrition.salt)
    ? `Sāls ${trimZero(nutrition.salt)} g`
    : "";
  const fiber = existsAndIsNotZero(nutrition.fiber)
    ? `Šķiedrvielas ${nutrition.fiber} g`
    : "";
  const correspondingSalt = existsAndIsNotZero(nutrition.correspondingSalt)
    ? `atbilstošā sāls ${trimZero(nutrition.correspondingSalt)} g`
    : "";
  const natrium = existsAndIsNotZero(nutrition.natrium)
    ? `nātrijs ${trimZero(nutrition.natrium)} g`
    : "";

  //TODO vi borde kunna beräkna fontsize baserat på antalet tecken

  const nutritionText = [
    energy,
    fat,
    saturatedFat,
    carbs,
    sugar,
    starch,
    polyoler,
    protien,
    salt,
    fiber,
    correspondingSalt,
    natrium,
  ]
    .filter((t) => t.length > 0)
    .join(", ");

  return (
    <Text style={{ fontSize: 9, fontFamily: "PublicSans" }}>
      <Text
        style={{ fontSize: 9, fontFamily: "PublicSans", fontWeight: "bold" }}
      >
        Uzturvērtība (100g).{" "}
      </Text>
      {nutritionText}
    </Text>
  );
}

function printIngredients(ingredients: string) {
  function printNormal(value: string): ReactElement {
    return (
      <Text key={value} style={{ fontSize: 9, fontFamily: "PublicSans" }}>
        {value}
      </Text>
    );
  }

  function printBold(value: string): ReactElement {
    return (
      <Text
        key={value}
        style={{ fontSize: 9, fontFamily: "PublicSans", fontWeight: "bold" }}
      >
        {value}
      </Text>
    );
  }

  const toPrint = [] as ReactElement[];

  //Index:  152 453
  // CandyRow.tsx:93 Next Bold:  152
  // CandyRow.tsx:108 Printing  152 152

  let currentIndex = 0;
  while (currentIndex < ingredients.length - 1) {
    const nextBold = ingredients.indexOf("<b>", currentIndex);
    if (nextBold === currentIndex) {
      const endBold = ingredients.indexOf("</b>", currentIndex);
      toPrint.push(printBold(ingredients.substring(currentIndex + 3, endBold)));
      currentIndex = endBold + 4;
      continue;
    }
    //no bold
    if (nextBold === -1) {
      toPrint.push(printNormal(ingredients.substring(currentIndex)));
      break; //Nothing more to print
    }
    // långt bort
    toPrint.push(printNormal(ingredients.substring(currentIndex, nextBold)));
    currentIndex = nextBold;
  }

  return (
    <Text style={{ fontSize: 9, fontFamily: "PublicSans" }}>
      <Text
        style={{ fontSize: 9, fontFamily: "PublicSans", fontWeight: "bold" }}
      >
        Sastāvdaļas:{" "}
      </Text>
      <>{toPrint}</>
    </Text>
  );
}

function generateEan(ean: string) {
  //EAN kan vara trasig
  if (ean === undefined || ean.length < 6) {
    return null;
  }
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, ean);
  const barcode = canvas.toDataURL();

  return (
    <Image
      src={barcode}
      style={{ width: "30mm", marginTop: "2mm", marginRight: "5mm" }}
    />
  );
}

export const CandyPrintRow: FunctionComponent<CandyLabel> = ({
  candy,
  translations,
}) => {
  const eanBarCode = generateEan(candy.ean);

  return (
    <View
      wrap={false}
      break={false}
      style={{
        // borderRadius: 6,
        height: "40mm",
        paddingBottom: 2,
        marginTop: "5mm",
        marginLeft: "5mm",
        marginRight: "5mm",
        justifyContent: "space-between",
      }}
    >
      <Image src={rainbowHeader} style={{ width: "100%" }} />
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ width: "30mm" }}></View>
        <Text style={{ fontSize: 28, fontFamily: "PublicSans" }}>
          {translations.productName.toLocaleUpperCase()}
        </Text>
        {eanBarCode}
      </View>
      <View style={{ paddingHorizontal: 8 }}>
        {printIngredients(translations.ingredients ?? "")}
      </View>
      <View style={{ paddingHorizontal: 8 }}>
        {printNutrients(candy.nutritional ?? "")}
      </View>
    </View>
  );
};
