import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  TextField
} from "@mui/material";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArticleId } from "../api/CandyAPI";
import { useBreakpointDown } from "../hooks/useBreakpoint";
import { useSnackBar } from "../hooks/useSnackbar";
import { useUpdateInventory } from "../hooks/useUpdateInventory";

interface Props {
  count: number;
  articleId: ArticleId;
  onClose: (count: number) => void;
}

export const EditInventoryDialog: FunctionComponent<Props> = ({
  count,
  articleId,
  onClose,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [internalCount, setInternalCount] = useState<number>(Number(count));
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const sm = useBreakpointDown("sm");
  const { mutate: updateInventory } = useUpdateInventory(articleId);

  const handleOpen = () => setOpen(true);

  const handleOk = () => {
    updateInventory(internalCount, {
      onSuccess: () => {
        setInternalCount(internalCount);
      },
      onError: () => {
        showSnackBar(t("Error updating stock of article"), "error");
      },
    });
    onClose(internalCount);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const subtract = () => {
    setInternalCount(Math.max(internalCount - 1, 0));
  };

  const add = () => {
    setInternalCount(internalCount + 1);
  };

  return (
    <>
      <Link
        onClick={handleOpen}
        sx={{
          cursor: "pointer",
          fontSize: 12,
          display: "flex",
          alignItems: "center",
          lineHeight: 1,
        }}
      >
        <EditIcon
          fontSize="inherit"
          sx={{
            mr: 0.5,
          }}
        />
        {t("Adjust")}
      </Link>
      <Dialog open={open} onClose={handleClose} fullWidth={sm ? true : false}>
        <DialogTitle>{t("Update stock quantity")}</DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
          }}
        >
          <IconButton
            disabled={internalCount === 0}
            onClick={subtract}
            size="large"
            color="secondary"
          >
            <RemoveIcon fontSize="large" />
          </IconButton>
          <Box>
            <TextField
              id="outlined-basic"
              style={{width:"150px"}}
              value={internalCount}
              size="medium"
              type="number"
              onChange={(e) => {
                const count = parseInt(e.target.value);
                if(Number.isNaN(count)) {
                  return;
                }
                setInternalCount(count);
              }}
              variant="outlined"
            />
          </Box>
          <IconButton onClick={add} size="large" color="secondary">
            <AddIcon fontSize="large" />
          </IconButton>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOk}
            variant="contained"
            fullWidth
            color="primary"
          >
            {t("Update")}
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            fullWidth
            color="inherit"
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
