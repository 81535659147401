import { useSetRecoilState } from "recoil";
import { removeStockState } from "../state/removeStockState";
import { PickItem } from "../../../state/pickState";

export const useAddToRemoveStockList = () => {
  const setState = useSetRecoilState(removeStockState);

  return (
    { articleId, ...rest }: Omit<PickItem, "quantity" | "updated">,
    count = -1
  ) => {
    setState((oldState) => {
      const exists = oldState.items.find(
        (item) => item.articleId === articleId
      );

      const newList = exists
        ? oldState.items.map((item) =>
            item.articleId === articleId
              ? { ...item, quantity: item.quantity + count }
              : item
          )
        : [...oldState.items, { articleId, ...rest, quantity: count }];

      return {
        ...oldState,
        items: newList,
      };
    });
  };
};
