import { useSetRecoilState } from "recoil";
import { ArticleId } from "../api/CandyAPI";
import { printState } from "../state/printState";

export const useRemoveFromPrintList = () => {
  const setPrintState = useSetRecoilState(printState);

  return (articleId: ArticleId, index?: number) => {
    setPrintState((oldState) => {
      const lastIndex = oldState.items.lastIndexOf(articleId);

      const newPrintList =
        typeof index !== "undefined"
          ? oldState.items.filter((_, i) => i !== index)
          : lastIndex > -1
          ? [
              ...oldState.items.slice(0, lastIndex),
              ...oldState.items.slice(lastIndex + 1),
            ]
          : oldState.items;

      return {
        ...oldState,
        items: newPrintList,
      };
    });
  };
};
