import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import TranslateIcon from "@mui/icons-material/Translate";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery, useSuspenseQueries } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import {
  CandyArticle,
  CandyType,
  Nutrients,
  candyAPI,
} from "../../api/CandyAPI";
import { inventoryAPI } from "../../api/InventoryAPI";
import { orderAPI } from "../../api/OrderAPI";
import { AddToOrderDialog } from "../../components/AddToOrderDialog";
import { ArticleStockHistoryTable } from "../../components/ArticleStockHistoryTable";
import { CandyPage } from "../../components/CandyPage";
import { CandyTypeString } from "../../components/CandyTypeChip";
import { EditInventoryDialog } from "../../components/EditInventoryDialog";
import { Helmet } from "../../components/Helmet";
import { MuiButtonLink } from "../../components/MuiLink";
import { PriceChart } from "../../components/PriceChart";
import { ProviderInventoryChart } from "../../components/ProviderInventoryChart";
import { getCandyUnit } from "../../components/UnitPrice";
import { useArticleId } from "../../hooks/useArticleId";
import { useSaveArticle } from "../../hooks/useSaveArticle";
import { useSnackBar } from "../../hooks/useSnackbar";
import { LABEL_PRINT_PAGE_ROUTE } from "../LabelPrintPage";
import { TRANSLATE_ARTICLE_PAGE_ROUTE } from "../TranslateArticlePage";

export const CANDY_ARTICLE_PAGE_ROUTE = "/candy/:articleId";

const NUTRIENT_KEYS: (keyof Nutrients)[] = [
  "kj",
  "kcal",
  "fat",
  "satFat",
  "carbs",
  "sugar",
  "protein",
  "salt",
  "correspondingSalt",
  "natrium",
  "fiber",
  "starch",
  "polyoler",
];

export const CandyArticlePage: React.FunctionComponent = () => {
  return (
    <CandyPage title="Article" skeleton={<PageSkeleton />}>
      <InnerCandyArticlePage />
    </CandyPage>
  );
};

const InnerCandyArticlePage: React.FunctionComponent = () => {
  const articleId = useArticleId();
  const { showSnackBar } = useSnackBar();
  const [noPicture, setNoPicture] = useState(false);
  const { t } = useTranslation();

  const [{ data: article }, { data: inventory, refetch: refetchInventory }] =
    useSuspenseQueries({
      queries: [
        candyAPI.fetchArticle(articleId),
        inventoryAPI.fetchInventory(articleId),
        orderAPI.fetchPendingOrder(articleId),
      ],
    });

  const { data: pendingOrder } = useQuery(
    orderAPI.fetchPendingOrder(articleId)
  );

  const { handleSubmit, register, reset, control } = useForm<CandyArticle>({
    defaultValues: {
      ...article,
    },
  });
  const { mutate: saveArticle, isPending: isSaving } =
    useSaveArticle(articleId);

  const unit = useMemo(() => getCandyUnit(article?.candyType), [article]);

  useEffect(() => {
    reset(article);
  }, [article, reset]);

  const onFinish = (data: CandyArticle) => {
    (Object.keys(data.nutritional) as (keyof Nutrients)[]).forEach((key) => {
      if (data.nutritional[key] === "") {
        delete data.nutritional[key];
      }
    });

    saveArticle(
      { ...article, ...data, nutritional: data.nutritional },
      {
        onSuccess: () => {
          showSnackBar(t("Saved article"), "success");
        },
        onError: () => {
          showSnackBar(t("Failed to save article"), "error");
        },
      }
    );
  };

  const providerStockPrint = (providerStock: number) => {
    if (providerStock < 0) {
      return "0";
    }
    if (providerStock > 100) {
      return "> 100";
    }
    return providerStock;
  };

  if (!article) {
    return null;
  }

  return (
    <Box
      sx={{
        mx: 2,
      }}
    >
      <Helmet title={article.productName} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 2,
          gap: 2,
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Typography variant="h5" component="h1">
          {article.productName}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <MuiButtonLink
            to={generatePath(LABEL_PRINT_PAGE_ROUTE, {
              articleId: articleId || "",
            })}
            variant="text"
            color="primary"
            startIcon={<LocalPrintshopIcon />}
          >
            Print
          </MuiButtonLink>
          <MuiButtonLink
            to={generatePath(TRANSLATE_ARTICLE_PAGE_ROUTE, {
              articleId,
            })}
            variant="text"
            color="secondary"
            startIcon={<TranslateIcon />}
          >
            Translate article
          </MuiButtonLink>
        </Box>
      </Box>
      {typeof article.disabled !== "undefined" && (
        <Alert variant="filled" severity="error" sx={{ mt: 4 }}>
          {t("This article is no longer available")}
        </Alert>
      )}
      {inventory && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6} sm={3}>
            <Card>
              <CardContent>
                <Typography
                  variant="overline"
                  noWrap
                  lineHeight={1}
                  component="h2"
                  gutterBottom
                  sx={{ mb: 1 }}
                >
                  {t("Current Stock")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    component="p"
                    color="textSecondary"
                    lineHeight={1}
                  >
                    {inventory.currentStock ?? "-"}{" "}
                  </Typography>
                  <EditInventoryDialog
                    count={inventory.currentStock}
                    articleId={article.articleId}
                    onClose={() => refetchInventory}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/* <Grid item xs={6} sm={3}>
              <Card>
                <CardContent>
                  <Typography variant="overline" noWrap lineHeight={1} component="h2" gutterBottom>
                    Latest Opened
                  </Typography>
                  sx={{m1}}
                  <Typography
                    variant="body2"
                    component="p"
                    color="textSecondary" lineHeight={1}
                  >
                    {inventory.latestOpened ? inventory.latestOpened : "-"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid> */}
          <Grid item xs={6} sm={3}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography
                      variant="overline"
                      noWrap
                      lineHeight={1}
                      component="h2"
                      gutterBottom
                      sx={{ mb: 1 }}
                    >
                      {t("Order")}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      color="textSecondary"
                      lineHeight={1}
                    >
                      {pendingOrder?.count}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <AddToOrderDialog
                      displayName={article.displayName}
                      articleId={article.articleId}
                      disabled={article.disabled !== undefined}
                      existingOrder={pendingOrder?.count}
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Card>
              <CardContent>
                <Typography
                  variant="overline"
                  noWrap
                  lineHeight={1}
                  component="h2"
                  gutterBottom
                  sx={{ mb: 1 }}
                >
                  {t("Provider Stock")}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    component="p"
                    color="textSecondary"
                    lineHeight={1}
                  >
                    {providerStockPrint(inventory.providerStock)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Card>
              <CardContent>
                <Typography
                  variant="overline"
                  noWrap
                  lineHeight={1}
                  component="h2"
                  gutterBottom
                  sx={{ mb: 1 }}
                >
                  {t("Back in stock")}
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  color="textSecondary"
                  lineHeight={1}
                >
                  {inventory.backInStock ?? "-"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <Card>
            <CardContent>
              <Typography
                variant="overline"
                noWrap
                lineHeight={1}
                component="h2"
                gutterBottom
                sx={{ mb: 1 }}
              >
                {t("Base Price")}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                color="textSecondary"
                lineHeight={1}
              >
                {article.basePrice ? article.basePrice : "-"} kr
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Card>
            <CardContent>
              <Typography
                variant="overline"
                noWrap
                lineHeight={1}
                component="h2"
                gutterBottom
                sx={{ mb: 1 }}
              >
                {t("Our price")}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                color="textSecondary"
                lineHeight={1}
              >
                {article.ourPrice ? article.ourPrice : "-"} kr
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Card>
            <CardContent sx={{ pb: 1 }}>
              <Typography
                variant="overline"
                noWrap
                lineHeight={1}
                component="h2"
                gutterBottom
                sx={{ mb: 1 }}
              >
                {t("Price per {{unit}}", { unit })}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                color="textSecondary"
                lineHeight={1}
              >
                {article.ourPrice && article.packageSize
                  ? (article.ourPrice / article.packageSize).toFixed(2)
                  : "-"}{" "}
                kr
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Card>
            <CardContent>
              <Typography
                variant="overline"
                noWrap
                lineHeight={1}
                component="h2"
                gutterBottom
                sx={{ mb: 1 }}
              >
                {t("Package size")}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                color="textSecondary"
                lineHeight={1}
              >
                {article.packageSize ? article.packageSize : "-"} {unit}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Card
        sx={{
          my: 4,
        }}
        variant="outlined"
      >
        <CardContent>
          <form onSubmit={handleSubmit(onFinish)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <img
                  width="100%"
                  loading="lazy"
                  hidden={noPicture}
                  onError={() => setNoPicture(true)}
                  onLoad={() => setNoPicture(false)}
                  src={`https://storage.googleapis.com/candy-pictures/articles/${articleId}.jpg`}
                />
                {noPicture ? t("No picture available") : null}
              </Grid>
              <Grid container item xs={12} sm={6} spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label={t("Display Name")}
                    id="displayName"
                    inputRef={register("displayName").ref}
                    {...register("displayName")}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="EAN"
                    id="ean"
                    {...register("ean")}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel id="candyType">{t("Candy Type")}</InputLabel>
                    <Controller
                      name="candyType"
                      control={control}
                      defaultValue={"" as unknown as undefined}
                      render={({ field }) => (
                        <Select
                          labelId="candyType"
                          label={t("Candy Type")}
                          fullWidth
                          {...(field as SelectProps)}
                        >
                          {Object.values(CandyType).map((candyType) => (
                            <MenuItem value={candyType} key={candyType}>
                              {CandyTypeString(t)[candyType]}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={<Switch color="primary" />}
                    label={t("Gelatin free")}
                    labelPlacement="end"
                    {...register("gelatinFree")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={<Switch color="primary" />}
                    label={t("Palm oil free")}
                    labelPlacement="end"
                    {...register("palmOilFree")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={<Switch color="primary" />}
                    label={t("Vegan")}
                    labelPlacement="end"
                    {...register("vegan")}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t("Original Ingredient label")}
                  multiline
                  disabled
                  minRows={3}
                  {...register("ingredientsOriginal")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t("Ingredients")}
                  multiline
                  minRows={3}
                  {...register("ingredients")}
                />
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("Nutrient")}</TableCell>
                        <TableCell>{t("Value")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {NUTRIENT_KEYS.map((key) => (
                        <TableRow hover key={key}>
                          <TableCell>{key}</TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              variant="standard"
                              size="small"
                              {...register(`nutritional.${key}`)}
                              placeholder="-"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isSaving}
                >
                  {t("Save")}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      <Card variant="outlined">
        <CardContent>
          <Typography variant="overline" component="h3" gutterBottom>
            {t("Price history")}
          </Typography>

          {articleId && (
            <PriceChart
              articleId={articleId}
              candyType={article.candyType}
              packageSize={article.packageSize}
            />
          )}
        </CardContent>
      </Card>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="overline" component="h3" gutterBottom>
            {t("ERT Stock History")}
          </Typography>

          {articleId && <ProviderInventoryChart articleId={articleId} />}
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ mt: 2 }}>
        <CardContent>
          <Typography variant="overline" component="h3" gutterBottom>
            {t("Store inventory history")}
          </Typography>
          <ArticleStockHistoryTable articleId={articleId} />
        </CardContent>
      </Card>
    </Box>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={6} sm={3}>
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Skeleton variant="rounded" height={100} />
        </Grid>
      </Grid>
      <Skeleton variant="rounded" height={400} sx={{ mt: 4 }} />
    </>
  );
};
