import { useSetRecoilState } from "recoil";
import { printState } from "../state/printState";
import { ArticleId } from "../api/CandyAPI";

export const useAddToPrintList = () => {
  const setPrintState = useSetRecoilState(printState);

  return (articleId: ArticleId) => {
    setPrintState((oldState) => {
      const newPrintList = [...oldState.items, articleId];

      return {
        ...oldState,
        items: newPrintList,
      };
    });
  };
};
