import { useMutation, useQueryClient } from "@tanstack/react-query";
import { orderAPI } from "../../../api/OrderAPI";
import { candyAPI } from "../../../api/CandyAPI";

const queryKeys = [orderAPI.QUERY_KEY, candyAPI.QUERY_KEY];

export const useReconcileOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: orderAPI.markOrderAsReconciled,
    onSuccess: () => {
      queryKeys.forEach((key) =>
        queryClient.invalidateQueries({ queryKey: [key] })
      );
    },
  });
};
