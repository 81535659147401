import { Html5QrcodeResult } from "html5-qrcode";
import { Html5QrcodeError, Html5QrcodeErrorTypes } from "html5-qrcode/esm/core";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ScanInfoSheet } from "../components/ScanInfoSheet";
import { Scanner } from "../components/Scanner";
import { CandyPage } from "../components/CandyPage";
import { useFeedback } from "../hooks/useFeedback";
import { DevButtons } from "../components/DevButtons";
import { AuthSheet } from "../components/AuthSheet";
import { RemoteId } from "../api/AuthAPI";

export const SCAN_ARTICLE_PAGE_ROUTE = "/scan";

const WHITELISTED_DOMAINS = [
  "admin.rainbowcandy.lv/authenticate",
  "localhost:",
];

const isWhitelistedUrl = (text: string) => {
  return (
    text.startsWith("http") &&
    WHITELISTED_DOMAINS.some((domain) => text.includes(domain))
  );
};

export const ScanArticlePage: FC = () => {
  const [ean, setEan] = useState<string | undefined>();
  const giveFeedback = useFeedback();
  const [link, setLink] = useState<string>();

  const remoteId = useMemo(() => {
    if (typeof link === "undefined") {
      return undefined;
    }
    const id = link.split("/authenticate/").pop() as RemoteId;
    return id;
  }, [link]);

  useEffect(() => {
    if (typeof link !== "undefined") {
      const timeout = setTimeout(() => {
        setLink(undefined);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [link]);

  const onScanSuccess = useCallback(
    (decodedText: string, decodedResult: Html5QrcodeResult) => {
      console.info(`Code matched = ${decodedText}`, decodedResult);
      if (isWhitelistedUrl(decodedText)) {
        if (typeof link === "undefined") {
          setLink(decodedText);
          giveFeedback();
        }
        return;
      }
      if (typeof ean === "undefined") {
        setEan(decodedText);
        giveFeedback();
      }
    },
    [ean, giveFeedback, link]
  );

  const onScanError = useCallback(
    (errorMessage: string, error: Html5QrcodeError) => {
      if (error.type === Html5QrcodeErrorTypes.UNKWOWN_ERROR) {
        return;
      }
      console.error(errorMessage);
    },
    []
  );

  return (
    <CandyPage fullHeight fullScreen title="Scan">
      <DevButtons onScanSuccess={onScanSuccess} />
      <Scanner
        onScanSuccess={(...props) => {
          onScanSuccess(...props);
        }}
        onScanError={onScanError}
        paused={typeof ean !== "undefined"}
        style={{
          flex: 1,
          objectFit: "cover",
        }}
        box
        aspectRatio={1}
      />
      <ScanInfoSheet ean={ean} onClose={() => setEan(undefined)} />
      <AuthSheet remoteId={remoteId} onClose={() => setLink(undefined)} />
    </CandyPage>
  );
};
