import {
  Autocomplete,
  Box,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { useMemo, useTransition } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  generatePath,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { CandySearch, CandyType } from "../api/CandyAPI";
import { SortDirection } from "../api/Page";
import { ArticleStatus } from "../components/ArticleStatus";
import { CandyPage } from "../components/CandyPage";
import { CandyTypeButton, CandyTypeChip } from "../components/CandyTypeChip";
import { MuiLink } from "../components/MuiLink";
import { Search } from "../components/Search";
import { UnitPrice } from "../components/UnitPrice";
import { useSuspendedArticles } from "../hooks/useArticles";
import { CANDY_ARTICLE_PAGE_ROUTE } from "./CandyArticlePage/CandyArticlePage";

const DEFAULT_PAGE_SIZE = 50;

const TYPE_FILTER = Object.entries(CandyType).map(([key]) => key as CandyType);

export const CandyListPage = () => {
  return (
    <CandyPage fullHeight title="All candy" skeleton={<PageSkeleton />}>
      <InnerCandyListPage />
    </CandyPage>
  );
};

const parseTypes = (types: string | null): CandyType[] | undefined => {
  if (types === null || types.length === 0 || !types) {
    return undefined;
  }

  return types.split(",").filter((t) => t in CandyType) as CandyType[];
};

const InnerCandyListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isPendingTransition, startTransition] = useTransition();

  const page = searchParams.get("page") || "0";
  const size = searchParams.get("size") || DEFAULT_PAGE_SIZE.toString();
  const types = useMemo(() => {
    return parseTypes(searchParams.get("types"));
  }, [searchParams]);

  const { data: result } = useSuspendedArticles(
    parseInt(page),
    parseInt(size),
    SortDirection.DESC,
    "created",
    types
  );

  const handleSearch = (result: CandySearch[]) => {
    if (result.length === 1) {
      navigate(
        generatePath(CANDY_ARTICLE_PAGE_ROUTE, {
          articleId: result[0].articleId,
        })
      );
      return;
    }
  };

  return (
    <>
      <Search onSearch={handleSearch} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "stretch",
          gap: 2,
          p: 2,
        }}
      >
        <Autocomplete
          multiple
          id="tags-outlined"
          options={TYPE_FILTER}
          onChange={(event, value) => {
            event.preventDefault();
            const typeParam = value.join(",");
            setSearchParams({
              page,
              size,
              types: typeParam,
            });
          }}
          value={types ? types : []}
          // getOptionLabel={(option) => CandyTypeString(t)[option]}
          renderOption={(props, option, { selected }) =>
            selected ? null : (
              <li {...props} key={option}>
                <CandyTypeChip candyType={option} />
              </li>
            )
          }
          renderTags={(tagValue) => {
            return tagValue.map((option) => (
              <div style={{ paddingRight: "5px" }} key={option}>
                <CandyTypeButton
                  size="medium"
                  candyType={option}
                  onDelete={() => {
                    if (!types) {
                      return;
                    }
                    const typeParam =
                      types.filter((t) => t !== option).join(",") || "";
                    setSearchParams({
                      page,
                      size,
                      types: typeParam,
                    });
                  }}
                />
              </div>
            ));
          }}
          sx={{ flex: 1 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Filter candy types")}
              placeholder="Filter types"
            />
          )}
        />
      </Box>
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t("Article")}</TableCell>
              <TableCell>{t("Display Name")}</TableCell>
              <TableCell>{t("Unit price")}</TableCell>
              <TableCell>{t("Type")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              opacity: isPendingTransition ? 0.5 : 1,
              transition: "opacity 0.15s ease-in-out",
            }}
          >
            {result?.content?.map((candy) => (
              <TableRow key={candy.articleId}>
                <TableCell>
                  <MuiLink
                    component={Link}
                    to={generatePath(CANDY_ARTICLE_PAGE_ROUTE, {
                      articleId: candy.articleId,
                    })}
                  >
                    {candy.articleId}
                  </MuiLink>
                </TableCell>
                <TableCell>
                  <ArticleStatus
                    disabled={candy.disabled}
                    // TODO: add prop when in backend
                    untranslated={
                      candy.translationName === "" ||
                      typeof candy.translationName === "undefined"
                    }
                    // translated={!candy.translationName}
                    onOrder={candy.pendingOrder}
                  >
                    {candy.displayName}
                  </ArticleStatus>
                </TableCell>
                <TableCell>
                  <UnitPrice
                    price={candy.kgPrice}
                    candyType={candy.candyType}
                  />
                </TableCell>
                <TableCell>
                  <CandyTypeChip candyType={candy.candyType} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, DEFAULT_PAGE_SIZE, 100]}
        component="div"
        count={result?.totalElements || 0}
        rowsPerPage={result?.size || DEFAULT_PAGE_SIZE}
        page={result?.number || 0}
        onPageChange={(_, page) => {
          startTransition(() => {
            const typeParam = types ? types.join(",") : "";
            setSearchParams({
              size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
              page: page.toString(),
              types: typeParam,
            });
          });
        }}
        onRowsPerPageChange={(event) => {
          startTransition(() => {
            const typeParam = types ? types.join(",") : "";
            setSearchParams({
              page: searchParams.get("page") || "0",
              size: event.target.value,
              types: typeParam,
            });
          });
        }}
      />
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10} sm={11}>
          <Skeleton variant="rounded" height={60} />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Skeleton variant="rounded" height={60} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(20)].map((_, i) => (
          <Grid item xs={12} key={i}>
            <Skeleton variant="rounded" height={50} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
