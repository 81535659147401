import { AtomEffect, atom } from "recoil";
import { ArticleId } from "../api/CandyAPI";

const localStorageEffect =
  (key: string): AtomEffect<PrintList> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: PrintList, _, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

const updateTimestamps =
  (): AtomEffect<PrintList> =>
  ({ setSelf, onSet }) => {
    onSet((newValue: PrintList) => {
      if (newValue.items.length === 0) {
        setSelf({
          ...newValue,
          lastUpdated: undefined,
        });
      }
      setSelf({
        ...newValue,
        lastUpdated: new Date().toISOString(),
      });
    });
  };

interface PrintList {
  lastUpdated: string | undefined;
  items: ArticleId[];
}

const defaultState = (): PrintList => ({
  lastUpdated: undefined,
  items: [],
});

const STATE_VERSION = "1";
const STATE_KEY = `print-state-${STATE_VERSION}`;

export const printState = atom<PrintList>({
  key: STATE_KEY,
  default:
    (localStorage.getItem(STATE_KEY) as unknown as PrintList) || defaultState(),
  effects: [updateTimestamps(), localStorageEffect(STATE_KEY)],
});
