import { generatePath, useNavigate } from "react-router-dom";
import { translationApi } from "../api/TranslationApi";
import { useEffect } from "react";
import { CandyPage } from "../components/CandyPage";
import {
  TRANSLATE_ARTICLE_PAGE_ROUTE,
  TranslateArticlePageSkeleton,
} from "./TranslateArticlePage";
import { useSuspenseQuery } from "@tanstack/react-query";

export const TRANSLATE_PAGE_ROUTE = "/translate";

export const NewTranslatePage: React.FunctionComponent = () => {
  return (
    <CandyPage title="Translate" skeleton={<TranslateArticlePageSkeleton />}>
      <InnerTranslatePage />
    </CandyPage>
  );
};

const InnerTranslatePage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { data: nextTranslation } = useSuspenseQuery(
    translationApi.fetchNextTranslation()
  );

  useEffect(() => {
    if (nextTranslation?.articleId) {
      navigate(
        generatePath(TRANSLATE_ARTICLE_PAGE_ROUTE, {
          articleId: nextTranslation.articleId,
        }),
        {
          replace: true,
        }
      );
    }
  }, [nextTranslation, navigate]);

  return null;
};
