import { Navigate, createBrowserRouter } from "react-router-dom";
import {
  ADMIN_USERS_PAGE,
  AdminUsersPage,
} from "../pages/Admin/AdminUsersPage";
import {
  AUTHENTICATE_PAGE_ROUTE,
  AuthenticatePage,
} from "../pages/Auth/AuthenticatePage";
import { LOGIN_PAGE_ROUTE, LoginPage } from "../pages/Auth/LoginPage";
import { REGISTER_PAGE_ROUTE, RegisterPage } from "../pages/Auth/RegisterPage";
import {
  CANDY_ARTICLE_PAGE_ROUTE,
  CandyArticlePage,
} from "../pages/CandyArticlePage/CandyArticlePage";
import {
  CandyInventoryPage,
  INVENTORY_PAGE_ROUTE,
} from "../pages/CandyInventoryPage";
import { CandyListPage } from "../pages/CandyListPage";
import {
  CANDY_NEXT_ORDER_PAGE_ROUTE,
  CandyNextOrderPage,
} from "../pages/CandyNextOrderPage";
import {
  CANDY_ORDER_PAGE_ROUTE,
  CandyOrderPage,
} from "../pages/CandyOrderPage";
import {
  CANDY_ORDER_RECONCILIATION_PAGE_ROUTE,
  CandyOrderReconciliationPage,
} from "../pages/CandyOrderReconciliationPage/CandyOrderReconciliationPage";
import {
  CANDY_ORDERS_PAGE_ROUTE,
  CandyOrdersPage,
} from "../pages/CandyOrdersPage";
import { CANDY_PICK_PAGE_ROUTE, CandyPickPage } from "../pages/CandyPickPage";
import {
  CANDY_REMOVE_STOCK_SCAN_PAGE,
  CandyRemoveStockScanPage,
} from "../pages/CandyRemoveStockScanPage/CandyRemoveStockScanPage";
import {
  CANDY_STOCK_SCAN_PAGE,
  CandyStockScanPage,
} from "../pages/CandyStockScanPage";
import {
  LABEL_PRINT_PAGE_BASE_ROUTE,
  LABEL_PRINT_PAGE_ROUTE,
  LabelPrintPage,
} from "../pages/LabelPrintPage";
import {
  SCAN_ARTICLE_PAGE_ROUTE,
  ScanArticlePage,
} from "../pages/ScanArticlePage";
import {
  TRANSLATE_ARTICLE_PAGE_ROUTE,
  TranslateArticlePage,
} from "../pages/TranslateArticlePage";
import { NewTranslatePage, TRANSLATE_PAGE_ROUTE } from "../pages/TranslatePage";
import { AppRoot } from "./AppRoot";
import { NotFoundPage } from "./NotFoundPage";
import {
  CandyInventoryNoStockPage,
  INVENTORY_NO_STOCK_PAGE_ROUTE,
} from "../pages/Inventory/CandyInventoryNoStockPage";
import {
  WEBSHOP_PRODUCTS_PAGE_ROUTE,
  WebshopProductsPage,
} from "../pages/Webshop/WebshopProductsPage";
import { ErrorPage } from "./ErrorPage";
import {
  INVENTORY_LOG_ROUTE,
  InventoryLogPage,
} from "../pages/Inventory/InventoryLogPage";
import {
  PIECE_PRICE_PAGE_ROUTE,
  PiecePricesPage,
} from "../pages/PiecePricesPage/PiecePricesPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppRoot />,
    ErrorBoundary: ErrorPage,
    children: [
      {
        path: "/",
        element: <CandyListPage />,
      },
      {
        path: TRANSLATE_ARTICLE_PAGE_ROUTE,
        element: <TranslateArticlePage />,
      },
      {
        path: TRANSLATE_PAGE_ROUTE,
        element: <NewTranslatePage />,
      },
      {
        path: LABEL_PRINT_PAGE_ROUTE,
        element: <LabelPrintPage />,
      },
      {
        path: LABEL_PRINT_PAGE_BASE_ROUTE,
        element: <LabelPrintPage />,
      },
      {
        path: CANDY_ARTICLE_PAGE_ROUTE,
        element: <CandyArticlePage />,
      },
      {
        path: SCAN_ARTICLE_PAGE_ROUTE,
        element: <ScanArticlePage />,
      },
      {
        path: CANDY_PICK_PAGE_ROUTE,
        element: <CandyPickPage />,
      },
      {
        path: CANDY_NEXT_ORDER_PAGE_ROUTE,
        element: <CandyNextOrderPage />,
      },
      {
        path: CANDY_ORDER_RECONCILIATION_PAGE_ROUTE,
        element: <CandyOrderReconciliationPage />,
      },
      {
        path: CANDY_ORDERS_PAGE_ROUTE,
        element: <CandyOrdersPage />,
      },
      {
        path: CANDY_ORDER_PAGE_ROUTE,
        element: <CandyOrderPage />,
      },
      {
        path: CANDY_STOCK_SCAN_PAGE,
        element: <CandyStockScanPage />,
      },
      {
        path: CANDY_REMOVE_STOCK_SCAN_PAGE,
        element: <CandyRemoveStockScanPage />,
      },
      {
        path: INVENTORY_PAGE_ROUTE,
        element: <CandyInventoryPage />,
      },
      {
        path: INVENTORY_NO_STOCK_PAGE_ROUTE,
        element: <CandyInventoryNoStockPage />,
      },
      {
        path: INVENTORY_LOG_ROUTE,
        element: <InventoryLogPage />,
      },
      {
        path: ADMIN_USERS_PAGE,
        element: <AdminUsersPage />,
      },
      {
        path: WEBSHOP_PRODUCTS_PAGE_ROUTE,
        element: <WebshopProductsPage />,
      },
      {
        path: PIECE_PRICE_PAGE_ROUTE,
        element: <PiecePricesPage />,
      },
      {
        path: "/admin",
        element: <Navigate to={ADMIN_USERS_PAGE} />,
      },
    ],
  },
  {
    path: LOGIN_PAGE_ROUTE,
    element: <LoginPage />,
  },
  {
    path: REGISTER_PAGE_ROUTE,
    element: <RegisterPage />,
  },
  {
    path: AUTHENTICATE_PAGE_ROUTE,
    element: <AuthenticatePage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
