const eans = [
  "4001686576106",
  "7340149208237"
];

export const randomEAN = () => {
  return eans[Math.floor(Math.random() * eans.length)];
};

export const roundToTwoDecimals = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};
