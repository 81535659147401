import BlockIcon from "@mui/icons-material/Block";
import {
  Autocomplete,
  Box,
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { CandyType } from "../../api/CandyAPI";
import { SortDirection } from "../../api/Page";
import {
  PiecePrice,
  ProviderStatus,
  piecePriceAPI,
} from "../../api/PiecePriceAPI";
import { AddToOrderDialog } from "../../components/AddToOrderDialog";
import { CandyPage } from "../../components/CandyPage";
import { CandyTypeButton, CandyTypeChip } from "../../components/CandyTypeChip";
import { MuiLink } from "../../components/MuiLink";
import { FormattedDate } from "../../components/date/FormattedDate";
import { SortLabel, getComparator } from "../../components/table/SortLabel";
import { CANDY_ARTICLE_PAGE_ROUTE } from "../CandyArticlePage/CandyArticlePage";
import { SetPriceDialog } from "./SetPriceDialog";

export const PIECE_PRICE_PAGE_ROUTE = "/piece-prices";

const TYPE_FILTER = [
  CandyType.CANDY_PIECE,
  CandyType.CHIPS,
  CandyType.PACKAGING,
  CandyType.DRINK,
];

function calculateMargin(selectedPrice: number, purchasePrice: number) {
  return ((selectedPrice - purchasePrice * 1.21) / selectedPrice) * 100;
}

export const PiecePricesPage = () => {
  return (
    <CandyPage
      fullHeight
      fullWidth
      title="Piece prices"
      skeleton={<PageSkeleton />}
    >
      <InnerCandyOrdersPage />
    </CandyPage>
  );
};

interface PieceRowProps {
  piece: PiecePrice;
  sekToEuro: number;
}

const PieceRow = ({ piece, sekToEuro }: PieceRowProps) => {
  //SEK per grej
  const [noPicture, setNoPicture] = useState(false);
  const pricePerPiece = piece.currentPrice / piece.pieces;
  const euroPerPiece = pricePerPiece / sekToEuro;
  //35% margin is suggested
  //21% är moms
  const suggested = (euroPerPiece / 0.65) * 1.21;
  const margin = piece.selectedPrice
    ? calculateMargin(piece.selectedPrice, euroPerPiece)
    : undefined;

  //Sätt bakgrundsfärg på:
  // - Ej prissat
  // - LEverantörspris ändrat efter uppdaterat

  return (
    <TableRow style={{ backgroundColor: "" }}>
      <TableCell padding="none" style={{ backgroundColor: "white" }}>
        <img
          width={80}
          loading="lazy"
          hidden={noPicture}
          onError={() => setNoPicture(true)}
          onLoad={() => setNoPicture(false)}
          src={`https://storage.googleapis.com/candy-pictures/articles/${piece.articleId}.jpg`}
        />
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" gap={1}>
          <CandyTypeChip candyType={piece.type} />
          <MuiLink
            style={{ marginLeft: "5px" }}
            component={Link}
            to={generatePath(CANDY_ARTICLE_PAGE_ROUTE, {
              articleId: piece.articleId,
            })}
          >
            {piece.displayName}{" "}
          </MuiLink>
          {piece.provider === ProviderStatus.NOT_AVAILABLE ? (
            <BlockIcon alignmentBaseline="central" color="error" />
          ) : null}
        </Stack>
      </TableCell>
      <TableCell>
        <SetPriceDialog
          piece={piece}
          sekToEuro={sekToEuro}
          suggestedPrice={suggested}
          suggestedMargin={35}
        />
      </TableCell>
      <TableCell>{piece.pieces}</TableCell>
      <TableCell align="center">{piece.currentPrice}kr </TableCell>
      <TableCell>
        {pricePerPiece.toFixed(2)}kr / €{euroPerPiece.toFixed(2)}
      </TableCell>
      <TableCell>{margin ? margin.toFixed(0) + " %" : "-"}</TableCell>
      <TableCell>
        <FormattedDate date={piece.priceSet} pattern="yyyy-MM-dd" />
      </TableCell>
      <TableCell>
        <FormattedDate date={piece.lastOrder} pattern="yyyy-MM-dd" />
        <AddToOrderDialog
          articleId={piece.articleId}
          existingOrder={piece.onOrder}
          displayName={piece.displayName}
          disabled={piece.provider === ProviderStatus.NOT_AVAILABLE}
        />
      </TableCell>
    </TableRow>
  );
};

const InnerCandyOrdersPage = () => {
  const { t } = useTranslation();
  const [sekToEuro, setSekToEuro] = useState(1);
  const [typeFilter, setTypeFilter] = useState<CandyType[]>(TYPE_FILTER);
  const { data: pieces } = useSuspenseQuery(piecePriceAPI.fetchFullList());
  useEffect(() => {
    piecePriceAPI.loadExchangeRate().then((r) => setSekToEuro(r.sekToEuro));
  }, []);

  const [order, setOrder] = useState<SortDirection>(SortDirection.ASC);
  const [orderBy, setOrderBy] = useState<keyof PiecePrice | "margin" | "piecePrice">(
    "articleId"
  );

  const updateSort = (property: keyof PiecePrice | "margin" | "piecePrice") => {
    const isAsc = orderBy === property && order === SortDirection.ASC;
    setOrder(isAsc ? SortDirection.DESC : SortDirection.ASC);
    setOrderBy(property);
  };

  //TODO indikera om när man senast spara är äldre än senaste priset, det kan indikera på att man bör uppdatera

  //TODO filter:
  // Endast aktiva
  // Endast beställda
  // TODO sätta pris på (Beställda men inte prissatta)

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "stretch",
          gap: 2,
          p: 2,
        }}
      >
        <Autocomplete
          multiple
          id="tags-outlined"
          options={TYPE_FILTER}
          onChange={(event, value) => {
            event.preventDefault();
            setTypeFilter(value);
          }}
          value={typeFilter}
          // getOptionLabel={(option) => CandyTypeString(t)[option]}
          renderOption={(props, option, { selected }) =>
            selected ? null : (
              <li {...props} key={option}>
                <CandyTypeChip candyType={option} />
              </li>
            )
          }
          renderTags={(tagValue) => {
            return tagValue.map((option) => (
              <div style={{ paddingRight: "5px" }} key={option}>
                <CandyTypeButton
                  size="medium"
                  candyType={option}
                  onDelete={() => {
                    const updated = typeFilter.filter((t) => t !== option);
                    setTypeFilter(updated);
                  }}
                />
              </div>
            ));
          }}
          sx={{ flex: 1 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Filter candy types")}
              placeholder="Filter types"
            />
          )}
        />
      </Box>
      <Box>
        
      </Box>
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <SortLabel
                label={t("Candy")}
                sortKey="articleId"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Price")}
                sortKey="selectedPrice"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <TableCell>{t("#")}</TableCell>
              <SortLabel
                label={t("Package Price")}
                sortKey="currentPrice"
                align="right"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
                            <SortLabel
                label="SEK / EUR"
                sortKey="piecePrice"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Margin")}
                sortKey="margin"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Updated")}
                sortKey="priceSet"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Last order")}
                sortKey="lastOrder"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {pieces
              .filter((price) => {
                if (typeFilter.length === 0) {
                  return true;
                }
                return typeFilter.includes(price.type);
              })
              .sort((a, b) => {
                if (orderBy === "margin") {
                  const marginA = a.selectedPrice
                    ? calculateMargin(
                        a.selectedPrice,
                        a.currentPrice / a.pieces / sekToEuro
                      )
                    : 0;
                  const marginB = b.selectedPrice
                    ? calculateMargin(
                        b.selectedPrice,
                        b.currentPrice / b.pieces / sekToEuro
                      )
                    : 0;
                  return getComparator(order, orderBy)(
                    { margin: marginA },
                    { margin: marginB }
                  );
                }

                if(orderBy === "piecePrice") {

                  const priceA = a.currentPrice / a.pieces;
                  const priceB = b.currentPrice / b.pieces;
                  return getComparator(order, orderBy)(
                    { piecePrice: priceA },
                    { piecePrice: priceB }
                  );

                }

                return getComparator(order, orderBy)(a, b);
              })
              .map((price) => (
                <PieceRow
                  key={price.articleId}
                  piece={price}
                  sekToEuro={sekToEuro}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(6)].map((_, i) => (
          <Grid item xs={12} key={i}>
            <Skeleton variant="rounded" height={50} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
