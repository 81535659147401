import { CredentialCreationOptionsJSON } from "@github/webauthn-json";
import {
  create,
  parseCreationOptionsFromJSON,
  supported,
} from "@github/webauthn-json/browser-ponyfill";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { API } from "../../api/network/API";
import { CandyPage } from "../../components/CandyPage";
import { motion } from "framer-motion";

export const REGISTER_PAGE_ROUTE = "/register/:inviteId";

interface StartRegistration extends CredentialCreationOptionsJSON {
  requestId: string;
}

interface InviteName {
  name: string;
}

export const RegisterPage = () => {
  const { inviteId } = useParams<{
    inviteId: string;
  }>();
  const [inviteName, setInviteName] = useState<string>();
  const [inviteFound, setInviteFound] = useState<boolean>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!inviteId) {
      return;
    }
    API.get<InviteName>(`/auth/register/${inviteId}`)
      .then((i) => {
        setInviteName(i.name);
        setInviteFound(true);
      })
      .catch(() => {
        setInviteFound(false);
      });
  }, [inviteId]);

  const takeInvite = (id: string) => {
    API.post<StartRegistration>("/auth/register?inviteId=" + id)
      .then(async ({ requestId, ...response }) => {
        const options = parseCreationOptionsFromJSON({ ...response });
        const credential = await create(options);
        await API.post("/auth/register/finish", {
          requestId,
          credential,
        });
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!inviteId) {
    return (
      <CandyPage fullHeight title="Register">
        <Typography variant="h4">Invalid invite</Typography>
      </CandyPage>
    );
  }

  if (inviteFound === undefined) {
    return (
      <CandyPage fullHeight title="Register">
        <Skeleton variant="rounded" />
      </CandyPage>
    );
  }

  if (inviteFound === false) {
    return (
      <CandyPage fullHeight title="Register">
        <Box
          sx={{
            m: 5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Invite not found
          </Typography>
        </Box>
      </CandyPage>
    );
  }

  return (
    <CandyPage fullHeight title="Register">
      <Box
        sx={{
          m: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 50,
            damping: 8,
          }}
        >
          <Typography
            initial={{ rotate: 0 }}
            animate={{
              rotate: [0, 15, -15, 15, -15, 15, -15, 0],
            }}
            transition={{
              repeat: Infinity,
              repeatType: "mirror",
              duration: 10,
            }}
            sx={{
              transformOrigin: "bottom right",
            }}
            component={motion.div}
            variant="h1"
            align="center"
            gutterBottom
          >
            👋
          </Typography>
        </motion.div>
        <Typography variant="h4" fontWeight={600} align="center" gutterBottom>
          Hello {inviteName}
        </Typography>
        <Typography variant="body1" align="center">
          {t(
            "You've been invited to join Rainbow Candy. Please register to continue."
          )}
        </Typography>
        <Box sx={{ my: 5 }}>
          <Button
            onClick={() => takeInvite(inviteId)}
            size="large"
            variant="contained"
            fullWidth
          >
            {t("Register")} <AppRegistrationIcon sx={{ ml: 1 }} />
          </Button>
        </Box>
        <Typography
          sx={{
            position: "absolute",
            bottom: 0,
            textAlign: "center",
            p: 2,
            opacity: 0.25,
          }}
          variant="caption"
        >
          {supported() ? "Supports WebAuthn" : "Does not support WebAuthn"}
        </Typography>
      </Box>
    </CandyPage>
  );
};
