import { FunctionComponent, useEffect } from "react";

interface Props {
  title: string;
}

export const Helmet: FunctionComponent<Props> = ({ title }) => {
  useEffect(() => {
    document.title = title + " - Rainbow Candy";
  }, [title]);

  return null;
};
