import {
  AppBar,
  Badge,
  Box,
  Fab,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Zoom,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { CandySearchModal } from "./CandySearchModal";
import { DRAWER_WIDTH } from "./CandySidebar";
import { ThemeContext } from "../state/ThemeContext";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { SCAN_ARTICLE_PAGE_ROUTE } from "../pages/ScanArticlePage";
import { CANDY_PICK_PAGE_ROUTE } from "../pages/CandyPickPage";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { useRecoilValue } from "recoil";
import { pickListCountSelector } from "../state/pickState";
import { useTranslation } from "react-i18next";
import { useKeyPress } from "../hooks/useKeyPress";
import { UserInfo } from "./UserInfo";

interface Props {
  isMobile: boolean;
  onOpenSidebar: () => void;
}

export const CandyAppBar: FunctionComponent<Props> = ({
  isMobile,
  onOpenSidebar,
}) => {
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const { toggleTheme } = useContext(ThemeContext);
  const location = useLocation();
  const pickCount = useRecoilValue(pickListCountSelector);
  const { t } = useTranslation();
  // search event
  useKeyPress(["f"], true, () => {
    setSearchOpen(true);
  });

  const showScanFab = isMobile && location.pathname !== SCAN_ARTICLE_PAGE_ROUTE;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const themeColor = theme.palette.background.default;
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor?.setAttribute("content", themeColor);
  }, [theme.palette]);

  useEffect(() => {
    const html = document.querySelector("html");
    html?.style.setProperty("color-scheme", theme.palette.mode);
  }, [theme.palette]);

  return (
    <>
      <CandySearchModal
        open={searchOpen}
        onClose={() => {
          setSearchOpen(false);
        }}
        isNarrow={isMobile}
      />
      <AppBar
        position="fixed"
        color="primary"
        variant="elevation"
        elevation={24}
        sx={{
          top: "auto",
          bottom: 0,
          left: isMobile ? 0 : `${DRAWER_WIDTH}px`,
          width: "auto",
          borderTopRightRadius: isMobile ? 20 : 30,
          borderTopLeftRadius: isMobile ? 20 : 0,
        }}
      >
        <Toolbar>
          {isMobile && (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onOpenSidebar}
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                color="inherit"
                onClick={() => navigate(CANDY_PICK_PAGE_ROUTE)}
              >
                <Badge
                  badgeContent={pickCount}
                  color="secondary"
                  variant="standard"
                >
                  <ChecklistIcon
                    sx={{
                      fill:
                        location.pathname === CANDY_PICK_PAGE_ROUTE
                          ? theme.palette.mode === "dark"
                            ? theme.palette.secondary.main
                            : theme.palette.secondary.light
                          : "#fff",
                    }}
                  />
                </Badge>
              </IconButton>

              <Zoom in={showScanFab} appear>
                <Fab
                  size="large"
                  variant="extended"
                  sx={{
                    position: "absolute",
                    zIndex: 1,
                    top: -24,
                    left: 0,
                    right: 0,
                    margin: "0 auto",
                    width: 80,
                    px: 4,
                    py: 3,
                    background: `conic-gradient(
                      rgba(255, 0, 0, 1) 0%,
                      rgba(255, 154, 0, 1) 10%,
                      rgba(208, 222, 33, 1) 20%,
                      rgba(79, 220, 74, 1) 30%,
                      rgba(63, 218, 216, 1) 40%,
                      rgba(47, 201, 226, 1) 50%,
                      rgba(28, 127, 238, 1) 60%,
                      rgba(95, 21, 242, 1) 70%,
                      rgba(186, 12, 248, 1) 80%,
                      rgba(251, 7, 217, 1) 90%,
                      rgba(255, 0, 0, 1) 100%
                    )`,
                  }}
                  aria-label="scan"
                  onClick={() => {
                    navigate(SCAN_ARTICLE_PAGE_ROUTE);
                  }}
                >
                  <QrCodeScannerIcon sx={{ fill: "white" }} />
                </Fab>
              </Zoom>
            </>
          )}
          {!isMobile && <UserInfo />}
          <Box sx={{ flexGrow: 1 }} />
          <IconButton color="inherit" onClick={() => setSearchOpen(true)}>
            <SearchIcon />
          </IconButton>

          <IconButton
            size="large"
            aria-label="more options"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                toggleTheme();
                handleClose();
              }}
            >
              <ListItemIcon>
                {theme.palette.mode === "dark" ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </ListItemIcon>
              <ListItemText>
                {t("Switch to {{theme}} mode", {
                  theme: theme.palette.mode === "dark" ? t("light") : t("dark"),
                })}
              </ListItemText>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
};
