import { useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { generatePath, useNavigate } from "react-router";
import { CandySearch, candyAPI } from "../api/CandyAPI";
import { CANDY_ARTICLE_PAGE_ROUTE } from "../pages/CandyArticlePage/CandyArticlePage";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  onClose: () => void;
  isNarrow?: boolean;
}

const style = {
  position: "absolute",
  top: "10%",
  left: "50%",
  transform: "translate(-50%, 0)",
  maxWidth: "500px",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
};

export const CandySearchModal = ({ open, onClose, isNarrow }: Props) => {
  const [search, setSearch] = useState<string>("");
  const [nonFound, setNonFound] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [results, setResults] = useState<CandySearch[]>([]);
  const { t } = useTranslation();

  const handleClose = () => {
    setSearch("");
    setResults([]);
    setNonFound(false);
    onClose();
  };

  const onSearch = () => {
    if (!search) {
      setResults([]);
      return;
    }

    setNonFound(false);
    setIsLoading(true);

    candyAPI
      .searchArticle(search)
      .then((result) => {
        if (result.length === 0) {
          setResults([]);
          setNonFound(true);
          return;
        }

        if (result.length > 1) {
          setResults(result);
          return;
        }

        if (result.length === 1) {
          handleClose();
          navigate(
            generatePath(CANDY_ARTICLE_PAGE_ROUTE, {
              articleId: result[0].articleId,
            })
          );
          return;
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          ...style,
          ...(isNarrow && {
            transform: "none",
            top: 0,
            width: "100%",
            left: "auto",
            maxWidth: "none",
          }),
        }}
        component="form"
        onSubmit={(event) => {
          event.preventDefault();
          onSearch();
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            p: 2,
          }}
        >
          <InputBase
            fullWidth
            placeholder={t("Search...")}
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            autoFocus
            error={nonFound}
            endAdornment={
              <Typography
                variant="overline"
                color="textSecondary"
                sx={{
                  whiteSpace: "nowrap",
                  px: 1,
                }}
              >
                {results.length > 0 && !nonFound && (
                  <span>
                    {t("{{quantity}} result", { quantity: results.length })}
                  </span>
                )}
              </Typography>
            }
          />
          {isLoading && (
            <IconButton type="button" sx={{ p: 1, mr: 1 }} aria-label="search">
              <CircularProgress color="inherit" size={24} />
            </IconButton>
          )}
          {!isLoading && (
            <IconButton
              type="button"
              sx={{ p: 1 }}
              onClick={() => {
                onSearch();
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
        </Box>
        {nonFound && (
          <>
            <Divider variant="middle" />
            <Typography
              variant="overline"
              align="center"
              color="textSecondary"
              sx={{
                p: 2,
              }}
            >
              {t("No results")}
            </Typography>
          </>
        )}
        {results.length > 0 && (
          <>
            <Divider variant="middle" />
            <List
              sx={{
                maxHeight: "400px",
                overflow: "auto",
              }}
            >
              {results.map((result) => (
                <ListItem
                  divider
                  key={result.articleId}
                  button
                  dense
                  onClick={() => {
                    navigate(
                      generatePath(CANDY_ARTICLE_PAGE_ROUTE, {
                        articleId: result.articleId,
                      })
                    );
                    handleClose();
                  }}
                >
                  <ListItemText
                    primary={result.name}
                    secondary={result.translationName ?? "-"}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Paper>
    </Modal>
  );
};
