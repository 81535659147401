import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQueries,
} from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { CandySearch, UTCDate, candyAPI } from "../api/CandyAPI";
import {
  Namespace,
  Translation,
  TranslationId,
  translationApi,
} from "../api/TranslationApi";
import { CandyPage } from "../components/CandyPage";
import { MuiButtonLink } from "../components/MuiLink";
import { Search } from "../components/Search";
import { FormattedDate } from "../components/date/FormattedDate";
import { useArticleId } from "../hooks/useArticleId";
import { useSnackBar } from "../hooks/useSnackbar";
import { CANDY_ARTICLE_PAGE_ROUTE } from "./CandyArticlePage/CandyArticlePage";
import { LABEL_PRINT_PAGE_ROUTE } from "./LabelPrintPage";

export const TRANSLATE_ARTICLE_PAGE_ROUTE = "/translate/:articleId";

interface NewTranslationForm {
  displayName: string;
  ingredients: string;
}

const validateBTags = (value: string) => {
  const open = value.match(/<b>/g)?.length ?? 0;
  const close = value.match(/<\/b>/g)?.length ?? 0;
  if (open !== close) {
    return "Number of <b> and </b> tags must be equal";
  }

  const openTags = value.match(/<b>/g) ?? [];
  const closeTags = value.match(/<\/b>/g) ?? [];

  const openIndexes = openTags.map((t) => value.indexOf(t));
  const closeIndexes = closeTags.map((t) => value.indexOf(t));

  for (let i = 0; i < openIndexes.length; i++) {
    if (openIndexes[i] > closeIndexes[i]) {
      return "A <b> tag must be closed before a new one is opened";
    }
  }

  return true;
};

export const TranslateArticlePage: React.FunctionComponent = () => {
  return (
    <CandyPage title="Translate" skeleton={<TranslateArticlePageSkeleton />}>
      <InnerTranslateArticlePage />
    </CandyPage>
  );
};

const InnerTranslateArticlePage: React.FunctionComponent = () => {
  const articleId = useArticleId();
  const navigate = useNavigate();
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [loadingSuggestion, setLoadingSuggestion] = useState(false);

  const [{ data: article }, { data: translation }] = useSuspenseQueries({
    queries: [
      candyAPI.fetchArticle(articleId),
      translationApi.fetchTranslation(articleId),
    ],
  });

  const ingredientsTranslation = useMemo(
    () => translation?.find((t) => t.namespace === Namespace.INGREDIENTS),
    [translation]
  );

  const displayNameTranslation = useMemo(
    () => translation?.find((t) => t.namespace === Namespace.DISPLAY_NAME),
    [translation]
  );

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    control,
  } = useForm<NewTranslationForm>({
    defaultValues: {
      displayName: displayNameTranslation?.value ?? "",
      ingredients: ingredientsTranslation?.value ?? "",
    },
  });

  const { mutate: saveTranslations, isPending: isSaving } = useMutation({
    mutationFn: translationApi.updateTranslations,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [articleId],
      });
      queryClient.invalidateQueries({
        queryKey: ["translation"],
      });
    },
  });

  const setDefault = useCallback(
    (
      ingredientsTranslation: Translation | undefined,
      displayNameTranslation: Translation | undefined
      // candyArticle: CandyArticle
    ) => {
      // if (
      //   typeof ingredientsTranslation === "undefined" &&
      //   typeof candyArticle.ingredients !== "undefined"
      // ) {
      //   let translation = candyArticle.ingredients;
      //   for (const key in KNOWN_WORDS) {
      //     const replacement = KNOWN_WORDS[key];
      //     translation = translation.replace(key, replacement);
      //   }
      //   setValue("ingredients", translation);
      // }

      if (typeof ingredientsTranslation !== "undefined") {
        setValue("ingredients", ingredientsTranslation.value);
      }

      if (typeof displayNameTranslation !== "undefined") {
        setValue("displayName", displayNameTranslation.value);
      }
    },
    [setValue]
  );

  useEffect(() => {
    reset();
    if (!article) {
      return;
    }
    setDefault(ingredientsTranslation, displayNameTranslation);
  }, [
    article,
    displayNameTranslation,
    ingredientsTranslation,
    reset,
    setDefault,
    translation,
  ]);

  const { data: translationCount } = useQuery({
    ...translationApi.fetchTranslationCount(),
  });

  const handleSearch = (result: CandySearch[]) => {
    if (result.length === 1) {
      navigate(
        generatePath(TRANSLATE_ARTICLE_PAGE_ROUTE, {
          articleId: result[0].articleId,
        })
      );
      return;
    }
  };

  const suggestTranslationGPT = () => {
    setLoadingSuggestion(true);
    translationApi
      .chatgptSuggest(articleId, "lv")
      .then((resp) => {
        setValue("ingredients", resp.suggestion);
      })
      .finally(() => {
        setLoadingSuggestion(false);
      });
  };

  const onSubmit = (data: NewTranslationForm) => {
    saveTranslations(
      [
        {
          articleId,
          id: 0 as TranslationId,
          language: "lv",
          namespace: Namespace.INGREDIENTS,
          created: "" as UTCDate,
          createdBy: "",
          ...(ingredientsTranslation ?? {}),
          value: data.ingredients,
        },
        {
          articleId,
          id: 0 as TranslationId,
          language: "lv",
          namespace: Namespace.DISPLAY_NAME,
          created: "" as UTCDate,
          createdBy: "",
          ...(displayNameTranslation ?? {}),
          value: data.displayName,
        },
      ],
      {
        onSuccess: () => {
          showSnackBar(t("Translation saved"), "success");
        },
        onError: () => {
          showSnackBar(
            t("Error saving translation, please try again"),
            "error"
          );
        },
      }
    );
  };

  return (
    <CandyPage title="Translate">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Search onSearch={handleSearch} sx={{ flex: 1, width: "100%" }} />
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <MuiButtonLink
            to={generatePath(LABEL_PRINT_PAGE_ROUTE, {
              articleId: articleId || "",
            })}
            color="secondary"
            variant="outlined"
            startIcon={<LocalPrintshopIcon />}
          >
            {t("Print")}
          </MuiButtonLink>
          <MuiButtonLink
            to={generatePath(CANDY_ARTICLE_PAGE_ROUTE, {
              articleId: articleId || "",
            })}
            color="inherit"
            variant="outlined"
          >
            {t("To article")}
          </MuiButtonLink>
        </Box>
      </Box>
      <Divider
        variant="middle"
        sx={{
          m: 2,
        }}
      />
      <Box
        sx={{
          m: 2,
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom>
          {article.productName}
        </Typography>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          component="h2"
          sx={{
            mb: 4,
          }}
        >
          EAN: {article.ean}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t("Display name")}
                disabled
                fullWidth
                value={article.displayName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="displayName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    label={t("Translated Display name")}
                    placeholder="..."
                    disabled={isSaving}
                    fullWidth
                    required
                    error={!!errors.displayName}
                    helperText={
                      errors.displayName?.message || errors.displayName?.type
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider
                variant="middle"
                sx={{
                  m: 2,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("Ingredients")}
                disabled
                multiline
                fullWidth
                rows={6}
                value={article.ingredients}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="ingredients"
                control={control}
                rules={{
                  required: true,
                  validate: validateBTags,
                }}
                render={({ field }) => (
                  <TextField
                    label={t("Translated Ingredients")}
                    disabled={isSaving || loadingSuggestion}
                    multiline
                    rows={6}
                    fullWidth
                    required
                    error={!!errors.ingredients}
                    helperText={
                      errors.ingredients?.message || errors.ingredients?.type
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <LoadingButton
                variant="contained"
                fullWidth
                disabled={isSaving || loadingSuggestion}
                type="submit"
                size="large"
                loading={isSaving}
              >
                {isSaving ? t("Saving...") : t("Save translation")}
              </LoadingButton>
            </Grid>
            <Grid item xs={12} md={4}>
              <LoadingButton
                variant="outlined"
                size="large"
                fullWidth
                disabled={isSaving || loadingSuggestion}
                onClick={suggestTranslationGPT}
                loadingPosition="start"
                loading={isSaving || loadingSuggestion}
              >
                {t("Suggest translation")}
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                {t("Latest saved:")}{" "}
                <FormattedDate date={ingredientsTranslation?.created} />
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="caption" gutterBottom>
                {translationCount?.translated ?? 0} /{" "}
                {translationCount?.total ?? 0} {t("translated")}
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Box>
    </CandyPage>
  );
};

export const TranslateArticlePageSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" height={60} sx={{ mt: 2 }} />
      <Skeleton variant="rounded" height={40} width="40%" sx={{ mt: 6 }} />
      <Skeleton variant="rounded" height={20} width={200} sx={{ mt: 2 }} />
      <Skeleton variant="rounded" height={60} sx={{ mt: 4 }} />
      <Skeleton variant="rounded" height={60} sx={{ mt: 2 }} />
      <Skeleton variant="rounded" height={200} sx={{ mt: 4 }} />
      <Skeleton variant="rounded" height={200} sx={{ mt: 2 }} />
    </>
  );
};
