import { FunctionComponent, useCallback } from "react";
import { Box, Grid, SwipeableDrawer, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { UpdateUser, usersAPI } from "../../../api/UsersAPI";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";
import { useSnackBar } from "../../../hooks/useSnackbar";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const Puller = () => {
  return (
    <Box
      sx={{
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
      }}
    />
  );
};

const drawerBleeding = 56;

export const CreateUserSheet: FunctionComponent<Props> = ({
  isOpen,
  onClose,
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={() => {
        // do nothing
      }}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Puller />
        <Box sx={{ p: 2 }}>
          <InnerSheet {...{ onClose, isOpen }} />
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

const InnerSheet: FunctionComponent<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBar();

  const { handleSubmit, register, reset: resetForm } = useForm<UpdateUser>();

  const { mutate: createUser, isPending: isSaving } = useMutation({
    mutationFn: usersAPI.createUser,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [usersAPI.QUERY_KEY],
      });
    },
  });

  const onSubmit = useCallback(
    (data: UpdateUser) => {
      createUser(data, {
        onSuccess: () => {
          onClose();
          resetForm();
          showSnackBar(t("Created user"), "success");
        },
        onError: () => {
          showSnackBar(t("Failed to create user"), "error");
        },
      });
    },
    [createUser, onClose, resetForm, showSnackBar, t]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        pt: 1,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("Display Name")}
              id="displayName"
              {...register("displayName", {
                required: true,
              })}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("Email")}
              id="email"
              {...register("email", {
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t("Entered value does not match email format"),
                },
              })}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              loading={isSaving}
            >
              {t("Create user")}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
