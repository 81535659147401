import {
  keepPreviousData,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { CandyType, candyAPI } from "../api/CandyAPI";
import { SortDirection } from "../api/Page";

export const useArticles = (
  page: number,
  size: number,
  direction: SortDirection,
  field: string
) => {
  return useQuery({
    ...candyAPI.fetchArticles(page, size, direction, field),
    placeholderData: keepPreviousData,
  });
};

export const useSuspendedArticles = (
  page: number,
  size: number,
  direction: SortDirection,
  field: string,
  types?: CandyType[]
) => {
  return useSuspenseQuery(
    candyAPI.fetchArticles(page, size, direction, field, types)
  );
};
