import { FunctionComponent, useEffect, useState } from "react";
import { CandySearch, candyAPI } from "../api/CandyAPI";
import {
  Autocomplete,
  Box,
  ListItemText,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";

interface Props {
  onSearch: (result: CandySearch[]) => void;
  onSearchError?: (error: Error) => void;
  sx?: SxProps<Theme>;
}

export const Search: FunctionComponent<Props> = ({
  onSearch,
  onSearchError,
  sx,
}) => {
  const [search, setSearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<CandySearch[]>([]);
  const [notFound, setNotFound] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleSearch = () => {
    setNotFound(false);
    if (!search) {
      setResults([]);
      return;
    }

    setIsLoading(true);

    return candyAPI
      .searchArticle(search)
      .then((response) => {
        setResults(response);
        if (response.length === 0) {
          setNotFound(true);
        }
      })
      .catch(onSearchError)
      .finally(() => setIsLoading(false));
  };

  const debouncedSearch = useDebouncedCallback(handleSearch, 250);

  useEffect(() => {
    debouncedSearch();
  }, [debouncedSearch, search]);

  return (
    <Box sx={sx}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSearch();
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
            gap: 2,
            p: 2,
          }}
        >
          <Autocomplete
            freeSolo
            autoHighlight
            openOnFocus
            disableCloseOnSelect
            filterOptions={(options) => {
              return options;
            }}
            options={results}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            renderOption={(props, option) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Box {...props}>
                <ListItemText
                  primary={option.name}
                  secondary={option.translationName ?? "-"}
                />
              </Box>
            )}
            onChange={(_, value) => {
              if (!value || typeof value === "string") {
                return;
              }
              onSearch([value]);
            }}
            sx={{ flex: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Search")}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Typography
                      variant="overline"
                      color="text.secondary"
                      sx={{
                        whiteSpace: "nowrap",
                        px: 1,
                      }}
                    >
                      {notFound && t("Not found")}
                      {results.length > 0 && (
                        <span>
                          {t("{{quantity}} results", {
                            quantity: results.length,
                          })}
                        </span>
                      )}
                    </Typography>
                  ),
                }}
              />
            )}
          />

          <LoadingButton
            variant="outlined"
            color="inherit"
            size="medium"
            type="submit"
            sx={{
              height: "auto",
              flexShrink: 0,
              "& .MuiButton-startIcon": {
                mr: 0,
              },
            }}
            startIcon={<SearchIcon />}
            loading={isLoading}
          />
        </Box>
      </form>
    </Box>
  );
};
