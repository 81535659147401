import { useRecoilState, useRecoilValue } from "recoil";
import { CandyPage } from "../../components/CandyPage";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ConfirmFab, ConfirmIconButton } from "../../components/ConfirmButton";
import { useSnackBar } from "../../hooks/useSnackbar";
import { Scanner } from "../../components/Scanner";
import { Html5QrcodeResult } from "html5-qrcode";
import { CandyArticle, candyAPI } from "../../api/CandyAPI";
import { useFeedback } from "../../hooks/useFeedback";
import { useCallback, useMemo, useState } from "react";
import { DevButtons } from "../../components/DevButtons";
import RemoveIcon from "@mui/icons-material/Remove";
import { inventoryAPI } from "../../api/InventoryAPI";
import { useRemoveFromRemoveStockList } from "./hooks/useRemoveFromRemoveStockList";
import { useAddToRemoveStockList } from "./hooks/useAddToRemoveStockList";
import {
  removeStockListCountSelector,
  removeStockState,
} from "./state/removeStockState";
import { useTranslation } from "react-i18next";

export const CANDY_REMOVE_STOCK_SCAN_PAGE = "/stock/remove/scan";

const TIMEOUT = 1000;

export const CandyRemoveStockScanPage: React.FunctionComponent = () => {
  const [{ items }, setItems] = useRecoilState(removeStockState);
  const itemCount = useRecoilValue(removeStockListCountSelector);
  const addArticle = useAddToRemoveStockList();
  const removeArticle = useRemoveFromRemoveStockList();
  const { showSnackBar } = useSnackBar();
  const feedback = useFeedback();
  const [throttled, setThrottled] = useState(false);
  const { t } = useTranslation();

  const itemsByReverseOrder = useMemo(() => [...items].reverse(), [items]);

  const handleSave = useCallback(() => {
    const batch = items.map((item) => ({
      articleId: item.articleId,
      count: item.quantity,
    }));
    inventoryAPI
      .moveBatchToInventory(batch)
      .then(() => {
        showSnackBar(
          t("Removed {{quantity}} items from inventory", {
            quantity: itemCount,
          }),
          "success"
        );
        setItems((oldState) => ({
          ...oldState,
          items: [],
        }));
      })
      .catch(() => {
        showSnackBar(
          t("Failed to remove {{quantity}} items from inventory", {
            quantity: itemCount,
          }),
          "error"
        );
      });
  }, [itemCount, items, setItems, showSnackBar, t]);

  const handleAdd = useCallback(
    (article: CandyArticle) => {
      addArticle(
        {
          ean: article.ean,
          articleId: article.articleId,
          displayName: article.displayName,
          candyType: article.candyType,
        },
        -1
      );
      showSnackBar(
        t("Added {{article}} to remove list", {
          article: article.displayName,
        }),
        "success"
      );
    },
    [addArticle, showSnackBar, t]
  );

  const onScanSuccess = useCallback(
    (decodedText: string, decodedResult: Html5QrcodeResult) => {
      if (throttled) {
        return;
      }

      console.info(`Code matched = ${decodedText}`, decodedResult);

      setThrottled(true);
      feedback();
      candyAPI
        .loadArticleByEan(decodedText)
        .then((article) => {
          handleAdd(article);
          showSnackBar(
            t("Added {{article}} to remove list", {
              article: article.displayName,
            }),
            "success"
          );
        })
        .catch(() => {
          showSnackBar(
            t("No article found for {{decodedText}}", { decodedText }),
            "error"
          );
        })
        .finally(() => {
          setTimeout(() => {
            setThrottled(false);
          }, TIMEOUT);
        });
    },
    [feedback, handleAdd, showSnackBar, t, throttled]
  );

  return (
    <CandyPage fullHeight title="Remove inventory">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            flex: "1.25 1 0px",
            position: "relative",
            display: "flex",
            maxHeight: "60%",
            "&:after": (theme) => ({
              content: '""',
              position: "absolute",
              right: 0,
              bottom: 0,
              left: 0,
              height: 80,
              background: `linear-gradient(to top, ${theme.palette.background.paper} 0%, transparent 100%)`,
            }),
          }}
        >
          <TableContainer
            sx={{
              height: "100%",
              position: "relative",
              paddingBottom: 8,
              flex: 1,
            }}
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Name")}</TableCell>
                  <TableCell align="right">{t("Quantity")}</TableCell>
                  <TableCell align="right">{t("Action")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      <Typography variant="body1">
                        {t("Nothing added to list")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}

                {itemsByReverseOrder.map((candy) => (
                  <TableRow key={candy.articleId} hover>
                    <TableCell>{candy.displayName}</TableCell>
                    <TableCell align="right">
                      {Math.abs(candy.quantity)}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        display: "flex",
                        gap: "8px",
                      }}
                    >
                      <ConfirmIconButton
                        color="error"
                        confirmText={t("Remove one {{article}} from list?", {
                          article: candy.displayName,
                        })}
                        onConfirm={() => {
                          removeArticle(candy.articleId);
                          showSnackBar(
                            t("Removed {{article}} from list", {
                              article: candy.displayName,
                            }),
                            "info"
                          );
                        }}
                      >
                        <RemoveIcon />
                      </ConfirmIconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {items.length > 0 && (
            <Box
              sx={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                bottom: 0,
                pb: 2,
              }}
            >
              <ConfirmFab
                variant="extended"
                size="medium"
                color="secondary"
                confirmText={t(
                  "Are you sure you want to remove all {{quantity}} items from stock?",
                  { quantity: itemCount }
                )}
                onConfirm={handleSave}
              >
                {t("Remove {{quantity}} items from stock", {
                  quantity: itemCount,
                })}
              </ConfirmFab>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            flex: "1 1 0px",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            position: "relative",
            background: (theme) => theme.palette.action.focus,
          }}
        >
          <DevButtons onScanSuccess={onScanSuccess} />
          <Scanner
            onScanSuccess={onScanSuccess}
            onScanError={() => {
              // TODO: Show error
            }}
            style={{
              flex: 1,
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>
    </CandyPage>
  );
};
