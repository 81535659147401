import { PaletteMode, ThemeProvider, colors, createTheme } from "@mui/material";
import {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export type ThemeContextProps = {
  toggleTheme: () => void;
};

export const ThemeContext = createContext<ThemeContextProps>({
  toggleTheme: () => {
    // do nothing
  },
});

const baseTheme = createTheme();

export const ThemeContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [mode, setMode] = useState<PaletteMode>(
    (localStorage.getItem("theme") as PaletteMode | null) || "light"
  );

  const value = useMemo(
    () => ({
      toggleTheme: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  useEffect(() => {
    localStorage.setItem("theme", mode);
  }, [mode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light"
            ? {
                primary: {
                  main: colors.purple["A200"],
                  contrastText: colors.common.white,
                },
                secondary: {
                  main: colors.lightBlue["A400"],
                  contrastText: colors.common.white,
                },
              }
            : {
                primary: {
                  main: "#fea6ee",
                },
                secondary: {
                  main: "#a6c2fe",
                },
              }),
        },
        shape: {
          borderRadius: 10,
        },
        typography: {
          overline: {
            fontWeight: 500,
          },
        },
        components: {
          MuiCardContent: {
            styleOverrides: {
              root: {
                "&:last-child": {
                  paddingBottom: 16,
                },
              },
            },
          },
          MuiCard: {
            defaultProps: {
              // elevation: 3,
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                fontWeight: "800",
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              container: {
                [baseTheme.breakpoints.down("sm")]: {
                  alignItems: "end",
                },
              },
            },
          },
        },
      }),
    [mode]
  );

  return (
    <ThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
