import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { ResponsiveLine, Serie } from "@nivo/line";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { ArticleId, CandyType, candyAPI } from "../api/CandyAPI";
import { DateTime } from "luxon";
import { roundToTwoDecimals } from "../utils";
import { useTranslation } from "react-i18next";

interface Props {
  articleId: ArticleId;
  candyType?: CandyType;
  packageSize?: number;
}

export const PriceChart: FunctionComponent<Props> = ({
  articleId,
  packageSize = 1,
}) => {
  const [data, setData] = useState<Serie[]>([]);
  const theme = useTheme();
  const [display, setDisplay] = useState<"package" | "unit">("unit");

  const [toDate] = useState<DateTime>(DateTime.now());
  const [fromDate] = useState<DateTime>(DateTime.now().minus({ months: 6 }));

  const { t } = useTranslation();

  useEffect(() => {
    candyAPI
      .articlePriceHistory(
        articleId,
        fromDate.toISODate() as string,
        toDate.toISODate() as string
      )
      .then((response) => {
        const priceData = response
          .map(({ created, ourPrice }) => ({
            x: created.split("T")[0],
            y: ourPrice,
          }))
          .filter((point, index, self) => {
            return (
              self.findIndex((p) => p.x === point.x && p.y === point.y) ===
              index
            );
          });

        setData([
          {
            id: "price",
            data: priceData,
          },
        ]);
      });
  }, [articleId, fromDate, toDate]);

  const computedData: Serie[] = useMemo(() => {
    if (data.length === 0) {
      return [];
    }

    if (display === "package") {
      return data;
    }

    return data.map((serie) => ({
      ...serie,
      data: serie.data.map((point) => ({
        ...point,
        y: roundToTwoDecimals(Number(point.y) / packageSize),
      })),
    }));
  }, [data, display, packageSize]);

  const minValue = useMemo(
    () =>
      computedData.reduce(
        (min, serie) =>
          serie.data.reduce(
            (min, point) => Math.min(min, point.y as number),
            min
          ),
        Infinity
      ),
    [computedData]
  );

  return (
    <>
      <Box
        sx={{
          maxHeight: 400,
          height: 400,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Tabs
          sx={{ flex: 0 }}
          value={display}
          variant="fullWidth"
          onChange={(_, newValue) => {
            setDisplay(newValue);
          }}
        >
          <Tab label={t("Unit price")} value="unit" />
          <Tab label={t("Package price")} value="package" />
        </Tabs>
        <Box sx={{ flex: 1, height: "100%" }}>
          <ResponsiveLine
            colors={theme.palette.primary.main}
            tooltip={(point) => {
              return (
                <Box
                  sx={{
                    background: theme.palette.grey[800],
                    color: theme.palette.grey[100],
                    p: 1,
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="body1">
                    {point.point.data.y.toString()} kr
                  </Typography>
                  <Typography variant="body2">
                    {new Date(point.point.data.x.toString()).toLocaleDateString(
                      "sv-SE"
                    )}
                  </Typography>
                </Box>
              );
            }}
            theme={{
              textColor: theme.palette.text.primary,
              crosshair: {
                line: {
                  stroke: theme.palette.text.primary,
                  strokeWidth: 1,
                  strokeOpacity: 0.5,
                  strokeDasharray: "6 6",
                },
              },
              tooltip: {
                container: {
                  background: theme.palette.grey[800],
                  color: theme.palette.grey[100],
                },
              },
              axis: {
                domain: {
                  line: {
                    stroke: theme.palette.text.primary,
                    strokeWidth: 1,
                  },
                },
                ticks: {
                  line: {
                    stroke: theme.palette.text.primary,
                    strokeWidth: 1,
                  },
                },
              },
              grid: {
                line: {
                  stroke: theme.palette.divider,
                  strokeWidth: 1,
                },
              },
            }}
            data={computedData}
            animate={true}
            useMesh={true}
            axisTop={null}
            axisRight={null}
            margin={{ top: 10, bottom: 75, left: 50, right: 25 }}
            curve="stepBefore"
            enableArea
            areaBaselineValue={minValue}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
              nice: true,
            }}
            xScale={{
              type: "time",
              format: "%Y-%m-%d",
              precision: "day",
              nice: true,
              min: fromDate.toISODate() as string,
              max: toDate.toISODate() as string,
            }}
            xFormat="time:%Y-%m-%d"
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: "middle",
            }}
            axisBottom={{
              format: "%b",
              tickValues: "every month",
              tickSize: 5,
              tickPadding: 5,
              legendOffset: -80,
            }}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            pointSize={0}
          />
        </Box>
      </Box>
    </>
  );
};
